/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssistsPageDetailOutputDTO } from '../models/AssistsPageDetailOutputDTO';
import type { PageAssistsPageDetailOutputDTO } from '../models/PageAssistsPageDetailOutputDTO';
import type { SingleChartsOutputDTO } from '../models/SingleChartsOutputDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomAssistsPageResourceService {

    /**
     * Return the assist page trend
     * Create a page with input data and return the map of assist page trends
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param siteId The ID of the site to filter by
     * @param routeId The ID of the route to filter by
     * @returns SingleChartsOutputDTO OK
     * @throws ApiError
     */
    public static getAssistPageTrend(
dateStart: string,
dateEnd: string,
timezone: string,
siteId?: number,
routeId?: number,
): CancelablePromise<Array<SingleChartsOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/assist-page-trend',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'siteId': siteId,
                'routeId': routeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Return assist page table detail 
     * Create a page with the input data, paginate it, and return the results page
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param siteId The ID of the site to filter by
     * @param routeId The ID of the route to filter by
     * @param pageNo The page number for pagination
     * @param pageSize The number of items page for pagination
     * @param sortBy The field to sort the results by
     * @param sortDir The sorting direction
     * @returns PageAssistsPageDetailOutputDTO OK
     * @throws ApiError
     */
    public static getAssistPageTableDetail(
dateStart: string,
dateEnd: string,
timezone: string,
siteId?: number,
routeId?: number,
pageNo?: number,
pageSize?: number,
sortBy?: string,
sortDir?: string,
): CancelablePromise<PageAssistsPageDetailOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/assist-page-table-detail',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'siteId': siteId,
                'routeId': routeId,
                'pageNo': pageNo,
                'pageSize': pageSize,
                'sortBy': sortBy,
                'sortDir': sortDir,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Return assist page table detail unpaged
     * Create a page with the input data, sort and collect it, and return the results page
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param siteId The ID of the site to filter by
     * @param routeId The ID of the route to filter by
     * @returns AssistsPageDetailOutputDTO OK
     * @throws ApiError
     */
    public static getAssistPageTableDetailUnpaged(
dateStart: string,
dateEnd: string,
timezone: string,
siteId?: number,
routeId?: number,
): CancelablePromise<Array<AssistsPageDetailOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/assist-page-table-detail-unpaged',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'siteId': siteId,
                'routeId': routeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Return assist page overview
     * Create a page with input data and return a map needed to generate a chart 
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param siteId The ID of the site to filter by
     * @param routeId The ID of the route to filter by
     * @returns number OK
     * @throws ApiError
     */
    public static getAssistPageOerview(
dateStart: string,
dateEnd: string,
timezone: string,
siteId?: number,
routeId?: number,
): CancelablePromise<Record<string, number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/assist-page-overview',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'siteId': siteId,
                'routeId': routeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
