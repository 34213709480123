import SiteAmr from "@/components/SiteAmr";
import img from "../../assets/images/MapIcon.svg";
import AmrMachinesIcon from "../../assets/images/SiteMachine.svg";
import { useRemoteGet, useWindowContext } from "@/hooks";
import {
  CustomAmrMachinePageResourceService,
  CustomSitesResourceService,
  CustomDeviceResourceService,
} from "@/services/openapi";
import machine_active_mobile from "@Images/icon_machine.svg";
import sites_active_mobile from "@Images/Icon_point.svg";
import { useContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./AmrMachines.module.css";
import Label from "@/components/UI/Label";
import { useTranslation } from "react-i18next";
import FilterBar from "@/components/Filter/Filterbar";
import Button from "@/components/UI/Button";
import CloseIcon from "../../assets/images/closeIcon.svg";
import Table from "@/components/UI/Table/Table";
import MainContext from "@/contexts/MainContext";
import { modelData } from "@/deviceModels";
import { PagingContextProvider } from "@/contexts/providers/PagingContextProvider";
import ButtonMobile from "@/components/UI/ButtonMobile";
import Sites from "../Sites";
import TableMobile from "@/components/UI/Table/TableMobile";
import { SelectInstance, SingleValue } from "react-select";
import { SelectDataProps } from "@Ui/Select/SelectBox";
import { ComboSelectProps } from "@/components/UI/Select/ComboSelect/ComboSelect";
import CustomLoader from "@/components/UI/CustomLoader";

const AmrMachines = () => {
  const { size, isMobile } = useWindowContext();
  // const authCtx = useContext(AuthContext);
  const { t } = useTranslation();
  const [showFiltersPage, setShowFiltersPage] = useState<boolean>(false);

  const modelRef = useRef<SelectInstance<SelectDataProps> | null>(null);
  const [model, setModel] = useState<string>("All");
  const [modelIsUserTriggered, setModelIsUserTriggered] =
      useState<boolean>(false);

  const siteRef = useRef<SelectInstance<SelectDataProps> | null>(null);
  const [site, setSite] = useState<string>("All");
  const [siteIsUserTriggered, setSiteIsUserTriggered] = useState<boolean>(false);

  const sitesData = useRemoteGet({
    remoteMethod: () => {
      if (model !== "All") {
        return CustomSitesResourceService.getSitesByDeviceModel(model);
      } else {
        //if(typeof fundingRequest.data?.id)
        // return CustomSitesResourceService.getAllSites1();
        return CustomSitesResourceService.getAllSites1();
      }
    },
    lazy: false,
  });

  const amrMachineSitesCount = useRemoteGet({
    remoteMethod: () => {
      //if(typeof fundingRequest.data?.id)
      // return CustomSitesResourceService.getAllSites1();
      return CustomAmrMachinePageResourceService.amrMachinePageCountDevice();
    },
    lazy: false,
  });

  const deviceData = useRemoteGet({
    remoteMethod: () => {
      if (site !== 'All') {
        return CustomDeviceResourceService.getDeviceModelBySite(parseInt(site));
      }
    },
    lazy: false,
  });

  const allMachines = useMemo(() => {
    let countMachines = 0;
    amrMachineSitesCount.data?.forEach((site) => {
      countMachines = countMachines + (site?.allMachine ?? 0);
    });
    return countMachines;
  }, [amrMachineSitesCount.data]);


  // Update sites filter when model change
  useEffect(() => {
    if (modelIsUserTriggered) {
      sitesData.fetch();
    }
  }, [model]);

  // Update models filter when site change
  useEffect(() => {
    if (siteIsUserTriggered) {
      deviceData.fetch();
    }
  }, [site]);

  useEffect(() => {
    if (siteIsUserTriggered && site === 'All' && model === 'All') {
      setSiteIsUserTriggered(false);
    }
  }, [site, model]);


  const setSitesOptionSelected = () => {
    let data: SelectDataProps = {value: "All", label: "All"};
    if (site !== "All" && sitesData.data) {
      const sites = sitesData.data.find((sites) => sites.id === parseInt(site));
      data = {value: sites?.id?.toString() ?? "", label: sites?.siteName ?? ""};
    }
    return data;
  };

  const setModelOptions = useCallback(() => {
    return modelData.map((val) => {
      return {value: val.key ?? "", label: val.model ?? ""};
    }) ?? [];
  }, []);

  const selectSites: ComboSelectProps = {
    selectData: sitesData.data?.map((val) => {
        return { value: "" + val.id ?? "", label: val.siteName ?? "" };
      }) ?? [],
    textLabel: t("AmrMachines.sites"),
    nameSelect: "selectSites",
    imgSrc: (size.width as number) > 900 ? undefined : sites_active_mobile,
    firstOption: "All",
    ref: siteRef,
    valueSelected: setSitesOptionSelected(),
    isUserTriggered: siteIsUserTriggered,
    onChange: (newValue, actionMeta, isUserTriggered) => {
      setSiteIsUserTriggered(isUserTriggered);
      setModelIsUserTriggered(false);

      const singleValue = newValue as SingleValue<SelectDataProps>;
      if (isUserTriggered && singleValue?.value === 'All') {
        setModel('All');
      }
      if (singleValue !== null && actionMeta.action === "select-option") {
        setSite(singleValue.value);
      }
    }
  };

  const selectModel: ComboSelectProps = {
    selectData: setModelOptions(),
    textLabel: t("AmrMachines.machineModel"),
    nameSelect: "selectModel",
    firstOption: "All",
    imgSrc: (size.width as number) > 900 ? undefined : machine_active_mobile,
    ref: modelRef,
    valueSelected: {value: model, label: model},
    isUserTriggered: modelIsUserTriggered,
    onChange: (newValue, actionMeta, isUserTriggered) => {
      setModelIsUserTriggered(isUserTriggered);
      // setSiteIsUserTriggered(false);

      const singleValue = newValue as SingleValue<SelectDataProps>;
      if (singleValue !== null && actionMeta.action === "select-option") {
        setModel(singleValue.value);
      }
    }
  };

  const colonne = {
    seriaN: t("columns.armMachines.seriaN"),
    rin: t("columns.armMachines.rin"),
    siteName: t("columns.armMachines.siteName"),
    siteLocation: t("columns.armMachines.siteLocation"),
    warrenStartDate: t("columns.armMachines.warrenStartDate"),
    model: t("columns.armMachines.model"),
    totalUsagePerDay: t("columns.armMachines.totalUsagePerDay"),
    autonomusUsage: t("columns.armMachines.autonomusUsage"),
    assistPerDay: t("columns.armMachines.assistPerDay"),
  };

  const titleCtx = useContext(MainContext);

  const [titleLoaded, setTitleLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!titleLoaded) {
      if (amrMachineSitesCount.data) {
        let amrMachineCounter = 0;
        amrMachineSitesCount.data.map((val) => {
          if (val.amrmachineNumber) {
            amrMachineCounter += val.amrmachineNumber;
          }
        });
        titleCtx.setTitle(
          <>
            <Label
              text={amrMachineCounter + " "}
              className={styles.titleLabelBlue}
            />
            <Label
              text={t("sidebar.amrMachines")}
              className={styles.titleLabel}
            />
          </>
        );
        setTitleLoaded(true);
      }
    }
  }, [amrMachineSitesCount]);

  const handleResetFilters = () => {
    setModelIsUserTriggered(true);
    setModel("All");
    setSite("All");
  };

  const buttonRef = useRef<HTMLButtonElement>(null);

  const applyFilters = () => {
    // console.log("MODEL: ", model, "\nSITE: ", site);
    if (!amrMachineSitesCount.loading) amrMachineSitesCount.fetch();
  };

  const loader = () => {
    if (amrMachineSitesCount.loading || sitesData.loading)
      return (
        <span>
          <CustomLoader searchLoader={true} />
        </span>
      );
    else return <span></span>;
  };

  return (
    <>
      <div className={styles.container}>
        <div>
          {isMobile && ( //---------------------------HEADER MOBILE
            <div className={styles.title_container}>
              <Label
                text={allMachines + " "}
                className={styles.title_labelColored}
              />
              <Label text={t("sidebar.amrMachines")} className={""} />
            </div>
          )}
          <div className={styles.subHeader_container}>
            <Label text={t("AmrMachines.in")} className={""} />
            <Label
              text={"" + amrMachineSitesCount.data?.length}
              className={styles.subHeader_labelColored}
            />
            <Label text={t("AmrMachines.sites")} className={""} />
          </div>
        </div>

        <div className={styles.sites_container}>
          <div className={styles.sitesScroll}>
            {amrMachineSitesCount.data?.map((site, id) => {
              return (
                <SiteAmr
                  type={"siteAMR"}
                  id={id}
                  titleImg={img}
                  titleOverlayImg={AmrMachinesIcon}
                  topSmallTitle={t("AmrMachines.site")}
                  titleText={site.site ?? ""}
                  divider1={true}
                  number={site.amrmachineNumber}
                  label1={"Tot. AMR Machine on site"}
                  progressBarColor="#20ACD9"
                  progressBarStatus={site.percent}
                  label2={"" + site.percent + "% are AMR machine"}
                  footerText={"" + site.allMachine + ": tot. machine on site"}
                />
              );
            })}
          </div>
        </div>

        {/*---------------- FILTER MOBILE START -------------------------*/}
        {isMobile && (
          <div className={styles.filterBar_container}>
            <div className={styles.filterBar_header}>
              {/* <Label text={t('AmrMachines.filters')} className={styles.filterLabel}/> */}
              <div className={styles.filter_mobile}>
                <ButtonMobile
                  text={t("assist.filters")}
                  onClick={() => {
                    setShowFiltersPage(!showFiltersPage);
                  }}
                  iconStart="filtersIcon.svg"
                />
                {/* <Button name={t('assist.filters')} className={styles.button_mobile} iconStart={'filterIcon.svg'} onClick={()=>{}}/> */}
              </div>
            </div>

            <div
              className={
                showFiltersPage
                  ? styles.filterBar_body
                  : styles.filterBar_body_hide
              }
            >
              <FilterBar
                filters={[
                  {
                    type: "Select",
                    selectData: selectModel,
                    imgSrc: machine_active_mobile,
                    boxClassName: styles.select_container,
                  },
                ]}
              />
              <FilterBar
                filters={[
                  {
                    type: "Select",
                    selectData: selectSites,
                    imgSrc: sites_active_mobile,
                    boxClassName: styles.select_container,
                  },
                ]}
              />
              <Button
                name={t("AmrMachines.resetFilters")}
                className={styles.resetFilters_btn}
                iconStart={CloseIcon}
                iconStartClassName={styles.resetFiltersIcon}
                onClick={handleResetFilters}
              />
            </div>

            <div className={styles.searchBtn_container}>
              <Button
                ref={buttonRef}
                name={t("dashboard.applyFilters")}
                className={styles.searchBtn}
                onClick={applyFilters}
              />
              {loader()}
            </div>
          </div>
        )}
        {/*---------------- FILTER MOBILE END -------------------------*/}

        <div className={styles.table_main_container}>
          {/*---------------- FILTER DESKTOP START -------------------------*/}
          {!isMobile && (
            <div className={styles.filterBar_container}>
              <div className={styles.filterBar_header}>
                <Label
                  text={t("AmrMachines.filters")}
                  className={styles.filterLabel}
                />
                <Button
                  name={t("AmrMachines.resetFilters")}
                  className={styles.resetFilters_btn}
                  iconStart={CloseIcon}
                  iconStartClassName={styles.resetFiltersIcon}
                  onClick={handleResetFilters}
                />
              </div>

              <div className={styles.filterBar_body}>
                <FilterBar
                  filters={[
                    {
                      type: "Select",
                      selectData: selectModel,
                      boxClassName: styles.select_container,
                    },
                  ]}
                />
                <FilterBar
                  filters={[
                    {
                      type: "Select",
                      selectData: selectSites,
                      boxClassName: styles.select_container,
                    },
                  ]}
                />
              </div>

              <div className={styles.searchBtn_container}>
                <Button
                  ref={buttonRef}
                  name={t("dashboard.applyFilters")}
                  className={styles.searchBtn}
                  onClick={applyFilters}
                />
                {loader()}
              </div>
            </div>
          )}
          {/*---------------- FILTER DESKTOP END -------------------------*/}

          <div className={styles.table_container}>
            <PagingContextProvider
              searchRef={buttonRef}
              queryParams={{ site, model }}
              remoteMethod={(queryParams, paging) => {
                return () => {
                  return CustomAmrMachinePageResourceService.amrMachinePageTable(
                    queryParams.site !== "All"
                      ? parseInt(queryParams.site!)
                      : undefined,
                    queryParams.model !== "All" ? queryParams.model : undefined,
                    paging.pageNumber,
                    paging.pageSize,
                    paging.sortBy, // TODO: Add sorting order
                    paging.sortOrder
                  );
                };
              }}
            >
              <>
                {!isMobile && (
                  <Table
                    columns={colonne}
                    type={"amrRanking"}
                    color={"blue"}
                    paging={true}
                  />
                )}
                {isMobile && (
                  <TableMobile
                    {...{ columns: colonne, type: "amrRanking", color: "blue" }}
                  />
                )}
              </>
            </PagingContextProvider>
          </div>
        </div>
        {isMobile && <Sites />}
      </div>
    </>
  );
};

export default AmrMachines;
