import styles from "./Dashboard.module.css";
import Label from "@Ui/Label";
import Box from "@Components/Box";
import calendar_active from "@Images/calendar_filter.svg";
import calendar_active_mobile from "@Images/icon_calendar.svg";
import machine_active from "@Images/MMachines_filter.svg";
import machine_active_mobile from "@Images/icon_machine.svg";
import {useTranslation} from "react-i18next";
import DoubleFilterBar from "@Components/Filter/DoubleFilterBar/DoubleFilterBar";
import sites_active from "@Images/sites_filter.svg";
import sites_active_mobile from "@Images/Icon_point.svg";

import LineCharts from "@Ui/Chart/LineCharts";
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useMemo,
  useState,
} from "react";
import Maps from "@/components/UI/Maps";
import {useRemoteGet} from "@/hooks/useRemoteGet";
import {
  CustomDashboardResourceService,
  CustomDeviceResourceService,
  CustomRoutesResourceService,
  CustomSitesResourceService,
} from "@/services/openapi";
import MainContext from "@/contexts/MainContext";
import AuthContext from "@/components/LoginForm/auth-context";
import {modelData} from "@/deviceModels";
import {useWindowContext} from "@/hooks";
import ButtonMobile from "@/components/UI/ButtonMobile/ButtonMobile";
import {formDateToTimestamp} from "@/utils";
import {SelectInstance, SingleValue, MultiValue} from "react-select";
import {SelectDataProps} from "@Ui/Select/SelectBox";
import Button from "@Ui/Button";
import {useFilterDateContext} from "@/hooks/FilterDateContext/useFilterDateContext";
import {FilterBarProps} from "@/components/Filter/Filterbar/FilterBar";
import {ComboDateProps} from "@/components/UI/Date/ComboDate/ComboDate";
import {ComboSelectProps} from "@/components/UI/Select/ComboSelect/ComboSelect";
import {LineChartsProps} from "@/components/UI/Chart/LineCharts/LineCharts";
import CustomLoader from "@/components/UI/CustomLoader";
import moment from "moment-timezone";
import {RoutesDTO} from "@/services/openapi";

let updated = false;
const Dashboard = () => {
  const {size, isMobile} = useWindowContext();
  const titleCtx = useContext(MainContext);
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    if (authCtx.firstName && !updated) {
      let timeOfDay = moment().hour() < 12;
      titleCtx.setTitle(
          <Label
              text={
                  (timeOfDay
                      ? t("dashboard.goodMorning")
                      : t("dashboard.goodAfternoon")) +
                  " " +
                  authCtx.firstName
              }
              className={styles.titleLabel}
          />
      );
      updated = true;
    }
  }, [authCtx]);

  const {t} = useTranslation();

  const [showFilterValue, setShowFilterValue] = useState<boolean>(true);

  const siteRef = useRef<SelectInstance<SelectDataProps> | null>(null);
  const [site, setSite] = useState<string>("All");
  const [siteIsUserTriggered, setSiteIsUserTriggered] = useState<boolean>(false);

  const routeRef = useRef<SelectInstance<SelectDataProps> | null>(null);
  const [route, setRoute] = useState<string>("All");
  const [routeIsUserTriggered, setRouteIsUserTriggered] =
      useState<boolean>(false);

  const modelRef = useRef<SelectInstance<SelectDataProps> | null>(null);
  const [model, setModel] = useState<string>("All");
  const [modelIsUserTriggered, setModelIsUserTriggered] =
      useState<boolean>(false);

  const typeRef = useRef<SelectInstance<SelectDataProps> | null>(null);
  const [type, setType] = useState<string>("All");
  const [typeIsUserTriggered, setTypeIsUserTriggered] = useState<boolean>(false);

  const rinRef = useRef<SelectInstance<SelectDataProps> | null>(null);
  const [multipleRin, setMultipleRin] = useState<string[]>([]);
  const [multipleRinIsUserTriggered, setMultipleRinIsUserTriggered] =
      useState<boolean>(false);

  const {date1, date2, setDate1, setDate2, timezone} = useFilterDateContext();
  const date1InputRef = useRef<HTMLInputElement>(null);
  const date2InputRef = useRef<HTMLInputElement>(null);

  const [showFiltersPage, setShowFiltersPage] = useState<boolean>(false);

  const sitesData = useRemoteGet({
    remoteMethod: () => {
      return CustomSitesResourceService.getAllSites1();
    },
    lazy: false,
  });

  const routesData = useRemoteGet({
    remoteMethod: () => {
      const siteId = isNaN(parseInt(site)) ? undefined : parseInt(site);
      return CustomRoutesResourceService.getRoutesByMultipleFilters(
          siteId,
          model,
          multipleRin.length === 0 ? ['All'] : multipleRin
      );
    },
    lazy: false,
  });

  const devicesData = useRemoteGet({
    remoteMethod: () => {
      const routeId = isNaN(parseInt(route)) ? undefined : parseInt(route);
      const siteId = isNaN(parseInt(site)) ? undefined : parseInt(site);
      if (siteIsUserTriggered && siteId) {
        return CustomDeviceResourceService.getAllDevicesBySiteId(siteId);
      }

      return CustomDeviceResourceService.getDevicesByMultipleFilters(
          siteId,
          routeId,
          model,
          multipleRin.length === 0 ? ['All'] : multipleRin
      );
    },
    lazy: false,
  });

  const coverageM2Data = useRemoteGet({
    remoteMethod: () => {
      return CustomDashboardResourceService.getCoverageM2PerHour(
          formDateToTimestamp(date1),
          formDateToTimestamp(date2, true),
          timezone,
          type !== "All" ? type : undefined,
          site !== "All" ? parseInt(site) : undefined,
          multipleRin.length === 0 ? undefined : multipleRin,
          model !== "All" ? model : undefined,
          route !== "All" ? parseInt(route) : undefined
      );
    },
    lazy: false,
  });

  const autoUsageData = useRemoteGet({
    remoteMethod: () => {
      return CustomDashboardResourceService.getAutonomousUsagePerc(
          formDateToTimestamp(date1),
          formDateToTimestamp(date2, true),
          timezone,
          type !== "All" ? type : undefined,
          site !== "All" ? parseInt(site) : undefined,
          multipleRin.length === 0 ? undefined : multipleRin,
          model !== "All" ? model : undefined,
          route !== "All" ? parseInt(route) : undefined
      );
    },
    lazy: false,
  });

  const assistData = useRemoteGet({
    remoteMethod: () => {
      return CustomDashboardResourceService.getAssistsPerHour(
          formDateToTimestamp(date1),
          formDateToTimestamp(date2, true),
          timezone,
          type !== "All" ? type : undefined,
          site !== "All" ? parseInt(site) : undefined,
          multipleRin.length === 0 ? undefined : multipleRin,
          model !== "All" ? model : undefined,
          route !== "All" ? parseInt(route) : undefined
      );
    },
    lazy: false,
  });

  const coverageM2ChartData =
      coverageM2Data.data?.length !== 0
          ? coverageM2Data.data?.map((obj) => {
        return {name: obj.date, uv: obj.value};
      }) ?? [{name: "", uv: 0}]
          : [{name: "", uv: 0}];

  const autoUsageChartData =
      autoUsageData.data?.length !== 0
          ? autoUsageData.data?.map((obj) => {
        return {name: obj.date, uv: obj.value};
      }) ?? [{name: "", uv: 0}]
          : [{name: "", uv: 0}];

  const assistChartData =
      assistData.data && assistData.data.singleChartsOutputDTO?.length !== 0
          ? assistData.data.singleChartsOutputDTO?.map((obj) => {
        return {name: obj.date, uv: obj.value};
      }) ?? [{name: "", uv: 0}]
          : [{name: "", uv: 0}];

  const comboDate: ComboDateProps = {
    idDate1: "data1",
    nameDate1: "Data1",
    valueDate1: date1,
    idDate2: "data2",
    nameDate2: "Data2",
    valueDate2: date2,
    min: "string",
    max: "string",
    labelName: "string",
    textLabel: t("dashboard.period"),
    imgSrc:
        (size.width as number) > 900 ? calendar_active : calendar_active_mobile,
    ref1: date1InputRef,
    onChangeDate1: (e: any) => {
      setDate1(e.target.value);
    },
    ref2: date2InputRef,
    onChangeDate2: (e: any) => {
      setDate2(e.target.value);
    },
  };

  useEffect(() => {
    sitesData.fetch();
    routesData.fetch();
    devicesData.fetch();
  }, []);

  // Update filters when site changes
  useEffect(() => {
    if (siteIsUserTriggered) {
      devicesData.fetch();
      routesData.fetch();
    }
  }, [site]);

  // Update filters when route changes
  useEffect(() => {
    if (routeIsUserTriggered) {
      devicesData.fetch();
    }
  }, [route]);

  // Update filters when machine model changes
  useEffect(() => {
    // sitesData.fetch();
    // routesData.fetch();
    if (modelIsUserTriggered) {
      devicesData.fetch();
    }
  }, [model]);

  useEffect(() => {
    if (siteIsUserTriggered && site === 'All' && model === 'All' && route === 'All' && multipleRin.length === 0) {
      setSiteIsUserTriggered(false);
    }
  }, [site, route, model, multipleRin]);

  const setSitesOptionsSelected = useCallback(
      (site: string) => {
        let data: SelectDataProps = {value: "All", label: "All"};

        const updateDataBySite = () => {
          const sites = sitesData.data?.find(
              (sites) => sites.id === parseInt(site)
          );
          data = {
            value: sites?.id?.toString() ?? "",
            label: sites?.siteName ?? "",
          };
          return data;
        };

        if (siteIsUserTriggered) {
          if (site === "All") {
            return data;
          } else {
            return updateDataBySite();
          }
        } else {
          if (multipleRin.length > 1) {
            return data;
          } else if (
              multipleRin.length === 1 &&
              !multipleRin.includes("All") &&
              sitesData.data &&
              devicesData.data
          ) {
            const device = devicesData.data.find(
                (device) => device.rin === multipleRin[0]
            );
            const sites = sitesData.data.find(
                (sites) => sites.id === device?.siteId
            );
            data = {
              value: sites?.id?.toString() ?? "",
              label: sites?.siteName ?? "",
            };
            return data;
          } else if (site !== "All") {
            return updateDataBySite();
          }
        }

        return data;
      },
      [devicesData.data, multipleRin, sitesData.data]
  );

  const setRoutesOptions = useCallback(() => {
    let data: SelectDataProps[] = [];

    const defaultData = () => {
      return routesData.data?.map((val) => {
        return {
          value: "" + val.id ?? "",
          label: `${val.routeName ?? ""}${
              val.routeLabel ? ` - ${val.routeLabel}` : ""
          }`,
        };
      }) ?? [];
    }

    if (
        !siteIsUserTriggered &&
        multipleRin.length === 1 &&
        !multipleRin.includes("All") &&
        sitesData.data &&
        devicesData.data
    ) {
      const device = devicesData.data.find(
          (device) => device.rin === multipleRin[0]
      );
      const sites = sitesData.data.find((sites) => sites.id === device?.siteId);
      const routes: RoutesDTO[] =
          routesData.data?.filter((route) => route.siteId === sites?.id) ?? [];
      routes.map((route) => {
        data.push({
          value: "" + route.id ?? "",
          label: `${route.routeName ?? ""}${
              route.routeLabel ? ` - ${route.routeLabel}` : ""
          }`,
        });
      });
    } else {
      return defaultData();
    }
    return data;
  }, [routesData.data]);

  const setSelectedRoute = useCallback(
      (route: string) => {
        let data: SelectDataProps = {value: "All", label: "All"};
        if (routesData.data && route !== "All") {
          const routes = routesData.data.find((r) => r.id === parseInt(route));
          data = {
            value: routes?.id?.toString() ?? "",
            label: routes?.routeName?.concat(" - " + routes.routeLabel) ?? "",
          };
        }
        return data;
      },
      [routesData.data]
  );

  const setModelOptions = useCallback(() => {
    return modelData.map((val) => {
      return {value: val.key ?? "", label: val.model ?? ""};
    }) ?? [];
  }, []);

  const selectTypeMachine: ComboSelectProps = {
    selectData: [{value: "AMR", label: "AMR"}],
    textLabel: t("dashboard.type"),
    nameSelect: "selectTypeMachine",
    imgSrc:
        (size.width as number) > 900 ? machine_active : machine_active_mobile,
    firstOption: "All",
    ref: typeRef,
    valueSelected: {value: type, label: type},
    isUserTriggered: typeIsUserTriggered,
    onChange: (newValue, actionMeta, isUserTriggered) => {
      setTypeIsUserTriggered(isUserTriggered);
      // setSiteIsUserTriggered(!isUserTriggered);
      // setRouteIsUserTriggered(!isUserTriggered);
      // setModelIsUserTriggered(!isUserTriggered);
      // setMultipleRinIsUserTriggered(!isUserTriggered);

      const singleValue = newValue as SingleValue<SelectDataProps>;
      if (singleValue !== null && actionMeta.action === "select-option") {
        setType(singleValue.value);
      }
    },
  };

  const selectSites: ComboSelectProps = {
    selectData:
        sitesData.data?.map((val) => {
          return {value: "" + val.id ?? "", label: val.siteName ?? ""};
        }) ?? [],
    textLabel: t("sidebar.sites"),
    nameSelect: "selectSites",
    imgSrc: (size.width as number) > 900 ? sites_active : sites_active_mobile,
    firstOption: "All",
    ref: siteRef,
    valueSelected: setSitesOptionsSelected(site),
    isUserTriggered: siteIsUserTriggered,
    onChange: (newValue, actionMeta, isUserTriggered) => {
      setSiteIsUserTriggered(isUserTriggered);
      setTypeIsUserTriggered(false);
      setMultipleRinIsUserTriggered(false);
      setModelIsUserTriggered(false);
      setRouteIsUserTriggered(false);

      const singleValue = newValue as SingleValue<SelectDataProps>;
      if (isUserTriggered && singleValue?.value === 'All') {
        setModel('All');
        setRoute('All');
        setMultipleRin([]);
      }
      if (singleValue !== null && actionMeta.action === "select-option") {
        setSite(singleValue.value);
      }
    },
  };

  const selectRoute: ComboSelectProps = {
    selectData: setRoutesOptions(),
    textLabel: t("dashboard.route"),
    nameSelect: "selectRoute",
    imgSrc: (size.width as number) > 900 ? undefined : machine_active_mobile,
    firstOption: "All",
    ref: routeRef,
    valueSelected: setSelectedRoute(route),
    isUserTriggered: routeIsUserTriggered,
    onChange: (newValue, actionMeta, isUserTriggered) => {
      setRouteIsUserTriggered(isUserTriggered);
      setSiteIsUserTriggered(false);
      // setTypeIsUserTriggered(!isUserTriggered);
      // setMultipleRinIsUserTriggered(!isUserTriggered);
      // setModelIsUserTriggered(!isUserTriggered);

      const singleValue = newValue as SingleValue<SelectDataProps>;
      if (singleValue !== null && actionMeta.action === "select-option") {
        setRoute(singleValue.value);
      }
    },
  };

  const selectModel: ComboSelectProps = {
    selectData: setModelOptions(),
    textLabel: t("dashboard.model"),
    nameSelect: "selectModel",
    imgSrc: (size.width as number) > 900 ? undefined : machine_active_mobile,
    firstOption: "All",
    ref: modelRef,
    valueSelected: {value: model, label: model},
    isUserTriggered: modelIsUserTriggered,
    onChange: (newValue, actionMeta, isUserTriggered) => {
      setModelIsUserTriggered(isUserTriggered);
      // setSiteIsUserTriggered(!isUserTriggered);
      // setRouteIsUserTriggered(!isUserTriggered);
      // setTypeIsUserTriggered(!isUserTriggered);
      // setMultipleRinIsUserTriggered(!isUserTriggered);

      const singleValue = newValue as SingleValue<SelectDataProps>;
      if (singleValue !== null && actionMeta.action === "select-option") {
        setModel(singleValue.value);
      }
    },
  };

  const selectRin: ComboSelectProps = {
    selectData:
        devicesData.data?.map((val) => {
          return {value: "" + val.rin ?? "", label: val.rin ?? ""};
        }) ?? [],
    textLabel: t("dashboard.rin"),
    nameSelect: "selectRin",
    imgSrc: (size.width as number) > 900 ? undefined : machine_active_mobile,
    ref: rinRef,
    multiValueSelected: multipleRin.map((rin) => {
      return {value: rin, label: rin};
    }),
    isMulti: true,
    isUserTriggered: multipleRinIsUserTriggered,
    onChange: (newValue, actionMeta, isUserTriggered) => {
      setMultipleRinIsUserTriggered(isUserTriggered);
      setSiteIsUserTriggered(false);
      // setRouteIsUserTriggered(!isUserTriggered);
      setModelIsUserTriggered(false);
      // setTypeIsUserTriggered(!isUserTriggered);

      const multiValue = newValue as MultiValue<SelectDataProps>;
      if (multiValue !== null) {
        if (multiValue.length === 0) {
          setMultipleRin([]);
        } else {
          const deviceValues = multiValue.map((item) => item.value);
          setMultipleRin(deviceValues);
          if (deviceValues.length === 1 && devicesData.data) {
            const device = devicesData.data.find(
                (device) => device.rin === deviceValues[0]
            );
            if (device && model !== device.deviceModel) {
              setModel(device.deviceModel!);
            }
          }
        }
      }
    },
  };

  const [errorDataPeriod, setErrorDataPeriod] = useState(false);

  const filterbar: FilterBarProps = {
    filters: [
      {
        type: "Date",
        selectData: comboDate,
        boxClassName: styles.box_period,
        dataError: errorDataPeriod,
      },
      {
        type: "Select",
        selectData: selectTypeMachine,
        boxClassName: styles.box_type,
      },
      {
        type: "Select",
        selectData: selectSites,
        boxClassName: styles.box_sites,
      },
      {
        type: "ButtonFilter",
        selectData: selectSites,
        boxClassName: styles.box_reset_btn,
      },
    ],
    showFilter: showFilterValue,
    showFilterCallback: () => {
      setShowFilterValue(!showFilterValue);
    },
  };

  const filterbar1: FilterBarProps = {
    filters: [
      {
        type: "Select",
        selectData: selectRoute,
        boxClassName: styles.boxContainer1,
      },
      {
        type: "Select",
        selectData: selectModel,
        boxClassName: styles.boxContainer1,
      },
      {
        type: "Select",
        selectData: selectRin,
        boxClassName: styles.boxContainer1,
      },
    ],
  };

  const filterbarMobile: FilterBarProps = {
    filters: [
      {
        type: "Date",
        selectData: comboDate,
        boxClassName: styles.box_period,
        dataError: errorDataPeriod,
      },
      {
        type: "Select",
        selectData: selectTypeMachine,
        boxClassName: styles.box_type,
      },
      {
        type: "Select",
        selectData: selectSites,
        boxClassName: styles.box_sites,
      },
      {
        type: "Select",
        selectData: selectRoute,
        boxClassName: styles.boxContainer1,
      },
      {
        type: "Select",
        selectData: selectModel,
        boxClassName: styles.boxContainer1,
      },
      {
        type: "Select",
        selectData: selectRin,
        boxClassName: styles.boxContainer1,
      },
    ],
  };

  const lineChart1: LineChartsProps = {
    id: "coverageM2",
    data: coverageM2ChartData,
    widthRC: "100%",
    heightRC: "100%",
    widthLineC: ((size.width as number) * 23) / 100,
    heightLineC: 200,
    dataKey: "name",
    top: 30,
    right: 25,
    left: -20,
    bottom: 20,
    color: "#8BC93D",
    labelX: t("chart.date"),
    labelY: t("chart.value"),
    labelY2: t("chart.previousValue"),
  };
  const lineChart2: LineChartsProps = {
    id: "autoUsage",
    data: autoUsageChartData,
    widthRC: "100%",
    heightRC: "100%",
    widthLineC: ((size.width as number) * 30) / 100,
    heightLineC: 200,
    dataKey: "name",
    top: 30,
    right: 25,
    left: -20,
    bottom: 20,
    color: "#09556F",
    labelX: t("chart.date"),
    labelY: t("chart.value"),
    labelY2: t("chart.previousValue"),
  };
  const lineChart3: LineChartsProps = {
    id: "assists",
    data: assistChartData,
    widthRC: "100%",
    heightRC: "100%",
    widthLineC: ((size.width as number) * 23) / 100,
    heightLineC: 200,
    dataKey: "name",
    top: 30,
    right: 25,
    left: -20,
    bottom: 20,
    color: "#FC8D41",
    labelX: t("chart.date"),
    labelY: t("chart.value"),
    labelY2: t("chart.previousValue"),
  };
  const lineChart1Mobile: LineChartsProps = {
    id: "coverageM2",
    data: coverageM2ChartData,
    widthRC: "100%",
    heightRC: "100%",
    widthLineC: size.width as number,
    heightLineC: 250,
    dataKey: "name",
    top: 20,
    right: 40,
    left: 0,
    bottom: 25,
    color: "#8BC93D",
    labelX: t("chart.date"),
    fontSizeYAxis: 11,
    labelY: t("chart.value"),
    labelY2: t("chart.previousValue"),
  };
  const lineChart2Mobile: LineChartsProps = {
    id: "autoUsage",
    data: autoUsageChartData,
    widthRC: "100%",
    heightRC: "100%",
    widthLineC: size.width as number,
    heightLineC: 250,
    dataKey: "name",
    top: 20,
    right: 40,
    left: 0,
    bottom: 25,
    color: "#09556F",
    labelX: t("chart.date"),
    fontSizeYAxis: 11,
    labelY: t("chart.value"),
    labelY2: t("chart.previousValue"),
  };
  const lineChart3Mobile: LineChartsProps = {
    id: "assists",
    data: assistChartData,
    widthRC: "100%",
    heightRC: "100%",
    widthLineC: size.width as number,
    heightLineC: 250,
    dataKey: "name",
    top: 20,
    right: 40,
    left: 0,
    bottom: 25,
    color: "#FC8D41",
    labelX: t("chart.date"),
    fontSizeYAxis: 11,
    labelY: t("chart.value"),
    labelY2: t("chart.previousValue"),
  };

  const buttonRef = useRef<HTMLButtonElement>(null);

  const applyFilters = useCallback(() => {
    console.log("TIPO: ", type, "\nSITE: ", site, "\nROUTE: ", route, "\nMODEL: ", model, "\nRIN: ", multipleRin);
    if (date1 <= date2) {
      setErrorDataPeriod(false);
      if (!coverageM2Data.loading) coverageM2Data.fetch();
      if (!autoUsageData.loading) autoUsageData.fetch();
      if (!assistData.loading) assistData.fetch();
    } else {
      setErrorDataPeriod(true);
      setTimeout(() => {
        setErrorDataPeriod(false);
      }, 2000);
    }
  }, [
    date1,
    date2,
    sitesData,
    routesData,
    devicesData,
    coverageM2Data,
    autoUsageData,
    assistData,
  ]);

  const loader = () => {
    if (
        sitesData.loading ||
        routesData.loading ||
        devicesData.loading ||
        coverageM2Data.loading ||
        autoUsageData.loading ||
        assistData.loading
    )
      return (
          <span>
          <CustomLoader searchLoader={true}/>
        </span>
      );
    else return <span></span>;
  };

  const applyFiltersButton = useMemo(() => {
    return (
        <div className={styles.searchBtn_container}>
          <Button
              ref={buttonRef}
              name={t("dashboard.applyFilters")}
              className={styles.searchBtn}
              onClick={applyFilters}
          />
          {loader()}
        </div>
    );
  }, [applyFilters, buttonRef]);

  return (
      <>
        <div className={styles.container}>
          {/* <div className={styles.subtitle_header}>
                    <Label text={t('dashboard.welcome')} className={styles.subtitle} />
                </div> */}
          {/**-----------------------MOBILE WELCOME AND FILTERS------------------------------ */}
          {isMobile && (
              <div className={styles.title_mobile}>
                <Label
                    text={t("dashboard.hi")}
                    className={styles.title_mobile_text}
                />
                <Label
                    text={authCtx.firstName + "!"}
                    className={styles.title_mobile_name}
                />
              </div>
          )}

          {isMobile && (
              <>
                <div className={styles.filter_mobile}>
                  <ButtonMobile
                      text={t("assist.filters")}
                      onClick={() => {
                        setShowFiltersPage(!showFiltersPage);
                      }}
                      iconStart="filtersIcon.svg"
                  />
                  {/* <Button name={t('assist.filters')} className={styles.button_mobile} iconStart={'filterIcon.svg'} onClick={()=>{}}/> */}
                </div>
                <div
                    className={
                      showFiltersPage
                          ? styles.filter_mobile_page
                          : styles.filter_mobile_page_hide
                    }
                >
                  <DoubleFilterBar filterBarData={filterbarMobile}/>
                </div>
              </>
          )}
          {/**-----------------------END MOBILE WELCOME AND FILTERS------------------------------ */}

          {/**-----------------------DESKTOP FILTER AND PLOTS------------------------------ */}

          {!isMobile && (
              <div className={styles.select_container}>
                <DoubleFilterBar filterBarData={filterbar}/>
                {showFilterValue && <DoubleFilterBar filterBarData={filterbar1}/>}
              </div>
          )}
          {applyFiltersButton}

          {
            <div className={styles.container_grafici}>
              <Box
                  className={styles.box_grafici_1}
                  children={
                    <div className={styles.box_chart}>
                      <div className={styles.title_chart_container}>
                        <Label
                            text={t("chart.coverage")}
                            className={styles.title_chart}
                        />
                      </div>
                      <div className={styles.chart}>
                        <LineCharts
                            {...((size.width as number) > 900
                                ? lineChart1
                                : lineChart1Mobile)}
                        />
                      </div>
                    </div>
                  }
              />
              <Box
                  className={styles.box_grafici_2}
                  children={
                    <div className={styles.box_chart}>
                      <div className={styles.title_chart_container}>
                        <Label
                            text={t("chart.usage")}
                            className={styles.title_chart}
                        />
                      </div>
                      <div className={styles.chart}>
                        <LineCharts
                            {...((size.width as number) > 900
                                ? lineChart2
                                : lineChart2Mobile)}
                        />
                      </div>
                    </div>
                  }
              />
              <Box
                  className={styles.box_grafici_1}
                  children={
                    <div className={styles.box_chart}>
                      <div className={styles.title_chart_container}>
                        <Label
                            text={t("chart.assist")}
                            className={styles.title_chart}
                        />
                      </div>
                      <div className={styles.chart}>
                        <LineCharts
                            {...((size.width as number) > 900
                                ? lineChart3
                                : lineChart3Mobile)}
                        />
                      </div>
                    </div>
                  }
              />
            </div>
          }

          {/**-----------------------END DESKTOP FILTER AND PLOTS------------------------------ */}

          <div className={styles.map_container}>
            <Maps
                className={styles.map}
                sites={
                  sitesData.data && sitesData.data.length > 0 ? sitesData.data : []
                }
            />
          </div>
        </div>
      </>
  );
};

export default Dashboard;
