/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageSitesPageOutputDTO } from '../models/PageSitesPageOutputDTO';
import type { SitesPageOutputDTO } from '../models/SitesPageOutputDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomSitesPageResourceService {

    /**
     * Retrieves site page table (paginated)
     * Generates a site page with input data, paginate id, and return a result page containing site page table 
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param best Best is a boolean necessary fot the sort of table 
     * @param typeMachine Type of machine to filter by
     * @param sitesId 
     * @param rins Device IDs to filter by
     * @param machineModel Model of machine
     * @param idRoute Route ID to filter by
     * @param pageNo The page number for pagination
     * @param pageSize The number of items page for pagination
     * @param sortBy The field to sort the results by
     * @param sortDir The sorting direction
     * @returns PageSitesPageOutputDTO OK
     * @throws ApiError
     */
    public static sitePageTable(
dateStart: string,
dateEnd: string,
timezone: string,
best: boolean,
typeMachine?: string,
sitesId?: number,
rins?: Array<string>,
machineModel?: string,
idRoute?: number,
pageNo?: number,
pageSize?: number,
sortBy?: string,
sortDir?: string,
): CancelablePromise<PageSitesPageOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/site-page-table',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'typeMachine': typeMachine,
                'sitesId': sitesId,
                'rins': rins,
                'machineModel': machineModel,
                'idRoute': idRoute,
                'best': best,
                'pageNo': pageNo,
                'pageSize': pageSize,
                'sortBy': sortBy,
                'sortDir': sortDir,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves site page table (without paginated)
     * Generates a site page with input data without paginate id, and return  a list of the site page table 
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param best Best is a boolean necessary fot the sort of table 
     * @param typeMachine Type of machine to filter by
     * @param sitesId 
     * @param rins Device IDs to filter by
     * @param machineModel Model of machine
     * @param idRoute Route ID to filter by
     * @returns SitesPageOutputDTO OK
     * @throws ApiError
     */
    public static sitePageTableUnpaged(
dateStart: string,
dateEnd: string,
timezone: string,
best: boolean,
typeMachine?: string,
sitesId?: number,
rins?: Array<string>,
machineModel?: string,
idRoute?: number,
): CancelablePromise<Array<SitesPageOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/site-page-table-unpaged',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'typeMachine': typeMachine,
                'sitesId': sitesId,
                'rins': rins,
                'machineModel': machineModel,
                'idRoute': idRoute,
                'best': best,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
