/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageRoutesAnalysisOutputDTO } from '../models/PageRoutesAnalysisOutputDTO';
import type { SingleChartsOutputDTO } from '../models/SingleChartsOutputDTO';
import type { SiteDetailPageDeviceOutputDTO } from '../models/SiteDetailPageDeviceOutputDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomSiteDetailPageResourceService {

    /**
     * Retrieve site detail page table information
     * Generates site detail page with input data and return the detail of result page
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param sitesId Sites ID to filter by
     * @returns SiteDetailPageDeviceOutputDTO OK
     * @throws ApiError
     */
    public static sitePageTable1(
dateStart: string,
dateEnd: string,
timezone: string,
sitesId?: number,
): CancelablePromise<Array<SiteDetailPageDeviceOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/site-detail-device-table',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'sitesId': sitesId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves all routes by device id
     * Generates a site detail page with input data, paginate it, and return a result page containing all routes by device id 
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param sitesId Sites ID to filter by
     * @param pageNo The page number for pagination
     * @param pageSize The number of items page for pagination
     * @param sortBy The field to sort the results by
     * @param sortDir The sorting direction
     * @returns PageRoutesAnalysisOutputDTO OK
     * @throws ApiError
     */
    public static getAllRoutesByDeviceId(
dateStart: string,
dateEnd: string,
timezone: string,
sitesId?: number,
pageNo?: number,
pageSize?: number,
sortBy?: string,
sortDir?: string,
): CancelablePromise<PageRoutesAnalysisOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/routes-analysis',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'sitesId': sitesId,
                'pageNo': pageNo,
                'pageSize': pageSize,
                'sortBy': sortBy,
                'sortDir': sortDir,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the number of routes of a specific site 
     * @param siteId Site ID to filter by
     * @returns number OK
     * @throws ApiError
     */
    public static getNumRoutesOnSite(
siteId: number,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/num-routes-site/{siteId}',
            path: {
                'siteId': siteId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the number of device of a specific site 
     * @param siteId Site ID to filter by
     * @returns number OK
     * @throws ApiError
     */
    public static getNumDeviceOnSite(
siteId: number,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/num-device-site/{siteId}',
            path: {
                'siteId': siteId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves M2 total covered for the last week
     * Creates the site detail page with input data and return a m2 covered for the last week
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param sitesId Sites ID to filter by
     * @returns number OK
     * @throws ApiError
     */
    public static getM2CoveredLastWeek(
dateStart: string,
dateEnd: string,
timezone: string,
sitesId?: number,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/m2-tot-covered',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'sitesId': sitesId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the chart representing the percentage of coverage area M2  
     * Generates a site detail page with input data and return a list of page representing the percentage og coverage area M2 for each day  
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param sitesId Sites ID to filter by
     * @returns SingleChartsOutputDTO OK
     * @throws ApiError
     */
    public static getM2CoveredPercChart(
dateStart: string,
dateEnd: string,
timezone: string,
sitesId?: number,
): CancelablePromise<Array<SingleChartsOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/m2-covered-perc-chart',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'sitesId': sitesId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
