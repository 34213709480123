/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagePcCatalogueDTO } from '../models/PagePcCatalogueDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomPcCatalogueResourceService {

    /**
     * Retrieves All PC Catalogue by model
     * Creates a paginated page and return a result page containing all pc catalougue associate of model specified in input
     * @param model The model to filter by
     * @param pageNo The page number for pagination
     * @param pageSize The number of items page for pagination
     * @param sortBy The field to sort the results by
     * @param sortDir The sorting direction
     * @returns PagePcCatalogueDTO OK
     * @throws ApiError
     */
    public static getAllPcCatalougueByModel(
model: string,
pageNo?: number,
pageSize?: number,
sortBy?: string,
sortDir?: string,
): CancelablePromise<PagePcCatalogueDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/pc-catalogue/{model}',
            path: {
                'model': model,
            },
            query: {
                'pageNo': pageNo,
                'pageSize': pageSize,
                'sortBy': sortBy,
                'sortDir': sortDir,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
