import { useWindowContext } from '@/hooks/WindowSizeContext/useWindowContext'
import styles from './Collapse.module.css'
import { useEffect, useState } from 'react'
import Label from '../Label'
import { usePagingContext } from '@/contexts/paging-context'


const Collapse = ({
    title,
    children,
    boxHeight }: { title: string, children: false | JSX.Element, boxHeight?: number, }) => {

    const {remoteGet} = usePagingContext();
    const {size,  isMobile } = useWindowContext()
    const [collapse, setCollapse] = useState<boolean>(false)
    const [height,setHeight] = useState<number>(boxHeight??0)
    useEffect(()=>{
        if(remoteGet.data?.content){
            setHeight((remoteGet?.data?.content?.length*60+160)+(boxHeight??0)) 
        }
    },[remoteGet])

    const classes = [styles.collapse];
    classes.push(!collapse ? styles.collapseClose : styles.collapseOpen);

    return (<>
        {isMobile &&
            <div className={classes.join(' ')} onClick={() => { setCollapse(!collapse) }}>
                <Label text={title} className={!collapse ? styles.titleLabelClose : styles.titleLabelOpen} />
                {!collapse ?
                    <Label text={'+'} className={styles.titleLabelClose} />
                    :
                    <Label text={'-'} className={styles.titleLabelOpen} />}
            </div>
        }
        {(size.width as number) >900 ?children:
            <div id={`collapse_animation_${title}`} className={!collapse ? styles.collapseAnimation_close : styles.collapseAnimation_open} aria-hidden={!collapse} style={!collapse ? {height:0} : {height:height}}>
                {children}
            </div>
        }

    </>)
}

export default Collapse;
