/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BenchmarkDTO } from '../models/BenchmarkDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomBenchmarkResourceService {

    /**
     * Save benchmark with a specific ID device
     * Save benchmark with a specific ID device and return its data
     * @param deviceId The ID of device to filters by
     * @param requestBody 
     * @returns BenchmarkDTO OK
     * @throws ApiError
     */
    public static saveBenchmark(
deviceId: number,
requestBody: BenchmarkDTO,
): CancelablePromise<BenchmarkDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/custom/benchmark/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves all benchmarks associated with a specific device ID
     *  Searches for benchmarks associated with a specific device ID and returns the list of matching benchmarks
     * @param deviceId The ID of device to filter by
     * @returns BenchmarkDTO OK
     * @throws ApiError
     */
    public static getAllBenchmarksByDevice(
deviceId: number,
): CancelablePromise<Array<BenchmarkDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/benchmarks/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
