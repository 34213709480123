import {useRemoteGet, useWindowContext} from "@/hooks";
import {
  CustomAssistsPageResourceService,
  CustomRoutesResourceService,
  CustomSitesResourceService,
} from "@/services/openapi";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./Assists.module.css";
import Label from "@/components/UI/Label";
import {useTranslation} from "react-i18next";
import FilterBar from "@/components/Filter/Filterbar";
import Button from "@/components/UI/Button";
import CloseIcon from "../../assets/images/closeIcon.svg";
import calendar_active from "@Images/calendar_filter.svg";
import Box from "@/components/Box";
import LineCharts from "@/components/UI/Chart/LineCharts";
import BarChart from "@/components/UI/Chart/BarChart";
import Table from "@/components/UI/Table/Table";
import downloadIcon from "../../assets/images/download.svg";
import MainContext from "@/contexts/MainContext";
import moment from "moment-timezone";
import {PagingContextProvider} from "@/contexts/providers/PagingContextProvider";
import DownloadButton from "@/components/UI/DownloadButton";
import {formDateToTimestamp} from "@/utils";
import {SelectInstance, SingleValue} from "react-select";
import {SelectDataProps} from "@Ui/Select/SelectBox";
import {useFilterDateContext} from "@/hooks/FilterDateContext/useFilterDateContext";
import ButtonMobile from "@/components/UI/ButtonMobile";
import machine_active_mobile from "@Images/icon_machine.svg";
import calendar_active_mobile from "@Images/icon_calendar.svg";
import sites_active_mobile from "@Images/Icon_point.svg";
import { LineChartsProps } from "@/components/UI/Chart/LineCharts/LineCharts";
import { BarChartProps } from "@/components/UI/Chart/BarChart/BarChart";
import CustomLoader from '@/components/UI/CustomLoader';
import { ComboSelectProps } from "@/components/UI/Select/ComboSelect/ComboSelect";

const Assist = () => {
  const {t} = useTranslation();
  const {size, isMobile} = useWindowContext();

  const [showFiltersPage, setShowFiltersPage] = useState<boolean>(false);

  const siteRef = useRef<SelectInstance<SelectDataProps> | null>(null);
  const [site, setSite] = useState<string>("All");
  const [siteIsUserTriggered, setSiteIsUserTriggered] = useState<boolean>(false);

  const routeRef = useRef<SelectInstance<SelectDataProps> | null>(null);
  const [route, setRoute] = useState<string>("All");
  const [routeIsUserTriggered, setRouteIsUserTriggered] =
      useState<boolean>(false);

  const {date1, date2, setDate1, setDate2, timezone} = useFilterDateContext();
  const date1InputRef = useRef(null);
  const date2InputRef = useRef(null);

  const sitesData = useRemoteGet({
    remoteMethod: () => {
      if (route !== "All") {
        return CustomSitesResourceService.getSiteByRoute(parseInt(route));
      } else {
        return CustomSitesResourceService.getAllSites1();
      }
    },
    lazy: false,
  });

  const routesData = useRemoteGet({
    remoteMethod: () => {
      if (site !== "All") {
        return CustomRoutesResourceService.getRoutesBySite(parseInt(site));
      } else {
        return CustomRoutesResourceService.getRoutesByUser();
      }
    },
    lazy: false,
  });

  // Update routes filter when site change
  useEffect(() => {
    if (siteIsUserTriggered) {
      routesData.fetch();
    }
  }, [site])

  // Update site filter when route change
  useEffect(() => {
    if (routeIsUserTriggered) {
      sitesData.fetch();
    }
  }, [route])

  useEffect(() => {
    if (siteIsUserTriggered && site === 'All' && route === 'All') {
      setSiteIsUserTriggered(false);
    }
  }, [site, route]);

  const setSitesOptionSelected = () => {
    let data: SelectDataProps = {value: "All", label: "All"};
    if (site !== "All" && sitesData.data) {
      const sites = sitesData.data.find((sites) => sites.id === parseInt(site));
      data = {value: sites?.id?.toString() ?? "", label: sites?.siteName ?? ""};
    }
    return data;
  };

  const setRoutesOptionSelected = () => {
    let data: SelectDataProps = {value: "All", label: "All"};
    if (route !== "All" && routesData.data) {
      const routes = routesData.data.find((routes) => routes.id === parseInt(route));
      data = {value: routes?.id?.toString() ?? "", label: routes?.routeName?.concat(" - " + routes.routeLabel) ?? ""};
    }
    return data;
  };
  
  const selectSites: ComboSelectProps = {
    selectData:
        sitesData.data?.map((val) => {
          return {value: "" + val.id ?? "", label: val.siteName ?? ""};
        }) ?? [],
    textLabel: t("sidebar.sites"),
    imgSrc: (size.width as number) > 900 ? undefined : sites_active_mobile,
    nameSelect: "selectSites",
    firstOption: "All",
    ref: siteRef,
    valueSelected: setSitesOptionSelected(),
    isUserTriggered: siteIsUserTriggered,
    onChange: (newValue, actionMeta, isUserTriggered) => {
      setSiteIsUserTriggered(isUserTriggered);

      const singleValue = newValue as SingleValue<SelectDataProps>;
      if (isUserTriggered && singleValue?.value === 'All') {
        setRoute('All');
      }
      if (singleValue !== null && actionMeta.action === "select-option") {
        setSite(singleValue.value);
      }
    }
  };

  const selectRoute: ComboSelectProps = {
    selectData:
        routesData.data?.map((val) => {
          return {
            value: "" + val.id ?? "",
            label: `${val.routeName ?? ""}${
                val.routeLabel ? ` - ${val.routeLabel}` : ""
            }`,
          };
        }) ?? [],
    textLabel: t("dashboard.route"),
    nameSelect: "selectRoute",
    firstOption: "All",
    imgSrc: (size.width as number) > 900 ? undefined : machine_active_mobile,
    ref: routeRef,
    valueSelected: setRoutesOptionSelected(),
    isUserTriggered: routeIsUserTriggered,
    onChange: (newValue, actionMeta, isUserTriggered) => {
      setRouteIsUserTriggered(isUserTriggered);
      setSiteIsUserTriggered(false);

      const singleValue = newValue as SingleValue<SelectDataProps>;
      if (singleValue !== null && actionMeta.action === "select-option") {
        setRoute(singleValue.value);
      }
    }
  };

  const pageTrendData = useRemoteGet({
    remoteMethod: () => {
      //if(typeof fundingRequest.data?.id)
      // return CustomSitesResourceService.getAllSites1();
      return CustomAssistsPageResourceService.getAssistPageTrend(
          formDateToTimestamp(date1),
          formDateToTimestamp(date2, true),
          timezone,
          site !== "All" ? parseInt(site) : undefined,
          route !== "All" ? parseInt(route) : undefined
      );
    },
    lazy: false,
  });

  const pageTrendChartData = pageTrendData.data?.map((obj) => {
    return {name: obj.date, uv: obj.value};
  }) ?? [{name: "", uv: 0}];

  const pageOverviewData = useRemoteGet({
    remoteMethod: () => {
      return CustomAssistsPageResourceService.getAssistPageOerview(
          formDateToTimestamp(date1),
          formDateToTimestamp(date2, true),
          timezone,
          site !== "All" ? parseInt(site) : undefined,
          route !== "All" ? parseInt(route) : undefined
      );
    },
    lazy: false,
  });

  const barChartData = pageOverviewData.data
      ? Object.keys(pageOverviewData.data).map((assist) => {
        return {name: assist, uv: pageOverviewData.data![assist], amt: 2};
      })
      : [];

  // useEffect(() => {
  //     if (!pageTrendData.loading) {
  //         pageTrendData.fetch()
  //     }
  //     if (!pageOverviewData.loading) {
  //         pageOverviewData.fetch()
  //     }
  // }, [date1, date2, site, route])

  const comboDate = {
    idDate1: "data1",
    nameDate1: "Data1",
    valueDate1: date1,
    idDate2: "data2",
    nameDate2: "Data2",
    valueDate2: date2,
    min: "string",
    max: "string",
    labelName: "string",
    textLabel: t("dashboard.period"),
    imgSrc:
        (size.width as number) > 900 ? calendar_active : calendar_active_mobile,

    ref1: date1InputRef,
    onChangeDate1: (e: any) => {
      setDate1(e.target.value);
    },
    ref2: date2InputRef,
    onChangeDate2: (e: any) => {
      setDate2(e.target.value);
    },
  };

  const lineChart1 = {
    id: "assistTrend",
    data: pageTrendChartData,
    widthRC: "100%",
    heightRC: "100%",
    widthLineC: 400,
    heightLineC: 200,
    dataKey: "name",
    top: 30,
    right: 25,
    left: -20,
    bottom: 20,
    color: "#09556F",
    bmLineY: 40,
    bmLineColor: "gray",
    labelX: t("chart.date"),
    labelY: t("chart.value"),
    labelY2: t("chart.previousValue"),
  };
  const lineChart1Mobile: LineChartsProps = {
    id: "assistTrend",
    data: pageTrendChartData,
    widthRC: "100%",
    heightRC: "100%",
    widthLineC: size.width as number,
    heightLineC: 250,
    dataKey: "name",
    top: 20,
    right: 40,
    left: 0,
    bottom: 25,
    color: "#09556F",
    bmLineY: 40,
    bmLineColor: "gray",
    labelX: t("chart.date"),
    fontSizeYAxis: 11,
    labelY: t("chart.value"),
    labelY2: t("chart.previousValue"),
  };

  const [maxYValue, setMaxYValue] = useState<number>(100);

  useEffect(() => {
    const maxValue = Math.max(...barChartData.map((entry) => entry.uv));
    const calculatedMaxYValue = (maxValue + 5) <= 100 ? (Math.round(( maxValue + 5) / 5) * 5) : 100;
    setMaxYValue(calculatedMaxYValue);
  }, [barChartData]);

  const barChart1 = {
    data: barChartData,
    widthRC: "100%",
    heightRC: "100%",
    widthBarC: 400,
    heightBarC: 200,
    barSize: 20,
    dataKey: "name",
    top: 30,
    right: 25,
    left: -20,
    bottom: 20,
    vertical: false,
    maxValue: maxYValue,
    labelX: t("assists.assist"),
    labelY: t("assists.percentage"),
  };
  const mobileYAxisViewed = [
    "BATTERY_VERY_LOW",
    "LOWER_SENSOR_DIRTY",
    "PATH_BLOCKED",
    "ESTOP",
  ];
  const barChart1Mobile: BarChartProps = {
    data: barChartData
        .filter((bcd) => mobileYAxisViewed.includes(bcd.name))
        .map((data) => {
          return {
            ...data,
            name: t(`assists.reasons.${data.name}`),
          };
        }),
    widthRC: "100%",
    heightRC: "100%",
    widthBarC: (size.width as number) - 60,
    heightBarC: 250,
    barSize: 20,
    dataKey: "name",
    top: 30,
    right: 15,
    left: -35,
    bottom: 20,
    vertical: true,
    maxValue: maxYValue,
    fontSizeYAxis: 8,
    labelX: t("assists.assist"),
    labelY: t("assists.percentage"),
  };

  const colonne: { [x: string]: string } = {
    rin: t("columns.assists.rin"),
    startDate: t("columns.assists.startDate"),
    siteName: t("columns.assists.siteName"),
    routeName: t("columns.assists.routeName"),
    assist: t("columns.assists.assist"),
  };

  const [titleLoaded, setTitleLoeaded] = useState<boolean>(false);
  const titleCtx = useContext(MainContext);

  //Setta il titolo della pagina
  useEffect(() => {
    titleCtx.setTitle(<Label text={t('sidebar.assists')} className={styles.titleLabel}/>);
  }, []);

  const handleResetFilters = () => {
    setDate1(moment().subtract(8, "days").format("YYYY-MM-DD"));
    setDate2(moment().subtract(1, "days").format("YYYY-MM-DD"));
    setSite("All");
    setRoute("All");
  };

  const teableHeader = (
      <div className={styles.tableHeader}>
        <DownloadButton
            name={t("assist.download")}
            className={styles.downloadBtn}
            iconStart={downloadIcon}
            columns={colonne}
            remoteMethod={() => {
              return CustomAssistsPageResourceService.getAssistPageTableDetailUnpaged(
                  formDateToTimestamp(date1),
                  formDateToTimestamp(date2, true),
                  timezone,
                  site !== "All" ? parseInt(site) : undefined,
                  route !== "All" ? parseInt(route) : undefined
              );
            }}
        />
      </div>
  );

  const [errorDataPeriod, setErrorDataPeriod] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const applyFilters = useCallback(() => {
    // console.log("SITES: ", site, "\nROUTES: ", route);
    if (date1 <= date2) {
      setErrorDataPeriod(false);
      if (!routesData.loading) routesData.fetch();
      if (!pageTrendData.loading) pageTrendData.fetch();
      if (!pageOverviewData.loading) pageOverviewData.fetch();
    } else {
      setErrorDataPeriod(true);
      setTimeout(() => {
        setErrorDataPeriod(false);
      }, 2000);
    }
  }, [date1, date2, pageOverviewData, pageTrendData, routesData]);

  const loader = () => {
    if (sitesData.loading 
      || routesData.loading
      || pageTrendData.loading
      || pageOverviewData.loading)
    return <span><CustomLoader searchLoader={true} /></span>
    else return <span></span>
  }

  const applyFiltersButton = useMemo(() => {
    return <div className={styles.searchBtnContainer}>
      <Button
          ref={buttonRef}
          name={t("dashboard.applyFilters")}
          className={styles.searchBtn}
          onClick={applyFilters}
      />
      {loader()}
    </div>
  }, [applyFilters, buttonRef]);

  return (
      <>
        <div className={styles.container}>
          {/*----------------------------FILTER DESKTOP START--------------------------------------------*/}
          {!isMobile && (
              <div className={styles.filterbar_main_container}>
                <div className={styles.filterBar_container}>
                  <div className={styles.filterBar_header}>
                    <Label
                        text={t("AmrMachines.filters")}
                        className={styles.filterLabel}
                    />
                    <Button
                        name={t("AmrMachines.resetFilters")}
                        className={styles.resetFilters_btn}
                        iconStart={CloseIcon}
                        iconStartClassName={styles.resetFiltersIcon}
                        onClick={handleResetFilters}
                    />
                  </div>
                  <div className={styles.filterBar_body}>
                    <FilterBar
                        filters={[
                          {
                            type: "Date",
                            selectData: comboDate,
                            boxClassName: styles.box_period,
                            dataError: errorDataPeriod,
                          },
                        ]}
                    />
                    <FilterBar
                        filters={[
                          {
                            type: "Select",
                            selectData: selectSites,
                            boxClassName: styles.select_container,
                          },
                        ]}
                    />
                    <FilterBar
                        filters={[
                          {
                            type: "Select",
                            selectData: selectRoute,
                            boxClassName: styles.select_container,
                          },
                        ]}
                    />
                  </div>
                </div>
              </div>
          )}
          {/*----------------------------FILTER DESKTOP END--------------------------------------------*/}

          {/*----------------------------FILTER MOBILE START--------------------------------------------*/}
          {isMobile && ( //---------------------------HEADER MOBILE
              <div className={styles.title_container}>
                <Label
                    text={sitesData.data?.length + " "}
                    className={styles.title_labelColored}
                />
                <Label text={t("sidebar.assists")} className={""}/>
              </div>
          )}

          {isMobile && (
              <div className={styles.filterBar_container}>
                <div className={styles.filterBar_header}>
                  {/* <Label text={t('AmrMachines.filters')} className={styles.filterLabel}/> */}
                  <div className={styles.filter_mobile}>
                    <ButtonMobile
                        text={t("assist.filters")}
                        onClick={() => {
                          setShowFiltersPage(!showFiltersPage);
                        }}
                        iconStart="filtersIcon.svg"
                    />
                    {/* <Button name={t('assist.filters')} className={styles.button_mobile} iconStart={'filterIcon.svg'} onClick={()=>{}}/> */}
                  </div>
                </div>

                <div
                    className={
                      showFiltersPage
                          ? styles.filterBar_body
                          : styles.filterBar_body_hide
                    }
                >
                  <FilterBar
                      filters={[
                        {
                          type: "Date",
                          selectData: comboDate,
                          boxClassName: styles.box_period,
                          dataError: errorDataPeriod,
                        },
                      ]}
                  />
                  <FilterBar
                      filters={[
                        {
                          type: "Select",
                          selectData: selectSites,
                          boxClassName: styles.select_container,
                        },
                      ]}
                  />
                  <FilterBar
                      filters={[
                        {
                          type: "Select",
                          selectData: selectRoute,
                          imgSrc: machine_active_mobile,
                          boxClassName: styles.select_container,
                        },
                      ]}
                  />
                  <Button
                      name={t("AmrMachines.resetFilters")}
                      className={styles.resetFilters_btn}
                      iconStart={CloseIcon}
                      iconStartClassName={styles.resetFiltersIcon}
                      onClick={handleResetFilters}
                  />
                </div>
              </div>
          )}
          {/*----------------------------FILTER MOBILE END--------------------------------------------*/}

          {applyFiltersButton}

          <div className={styles.charts_container}>
            <Box
                className={styles.box_grafico}
                children={
                  <div className={styles.box_chart}>
                    <div className={styles.title_chart_container}>
                      <Label
                          text={t("assist.assistTrend")}
                          className={styles.title_chart}
                      />
                    </div>
                    {/*<div className={styles.subtitle_chart_container}>*/}
                    {/*    <label text={t('assist.benchmark')} classname={styles.subtitle_chart}/>*/}
                    {/*    <label text={"" + 40} classname={styles.subtitle_chart}/>*/}
                    {/*</div>*/}
                    <div className={styles.chart}>
                      <LineCharts
                          {...((size.width as number) > 900
                              ? lineChart1
                              : lineChart1Mobile)}
                      />
                    </div>
                  </div>
                }
            />

            <Box
                className={styles.box_grafico}
                children={
                  <div className={styles.box_chart}>
                    <div className={styles.title_chart_container}>
                      <Label
                          text={t("assist.assistReasonsPerc")}
                          className={styles.title_chart}
                      />
                    </div>
                    {/*<div className={styles.title_chart_container}>*/}
                    {/*    <Label text={t('assist.assistOverview')} className={styles.title_chart}/>*/}
                    {/*    <Select name={''} selectData={[{value: "Last Month", text: "Last Month"}]}*/}
                    {/*            style={styles.selectOverview}/>*/}

                    {/*</div>*/}
                    <div className={styles.barChart}>
                      <BarChart
                          {...((size.width as number) > 900
                              ? barChart1
                              : barChart1Mobile)}
                      />
                    </div>
                  </div>
                }
            />
          </div>
          <PagingContextProvider
              searchRef={buttonRef}
              queryParams={{date1, date2, site, route}}
              remoteMethod={(queryParams, paging) => {
                return () => {
                  return CustomAssistsPageResourceService.getAssistPageTableDetail(
                      formDateToTimestamp(queryParams.date1!),
                      formDateToTimestamp(queryParams.date2!, true),
                      timezone,
                      queryParams.site !== "All" ? parseInt(site) : undefined,
                      queryParams.route !== "All" ? parseInt(route) : undefined,
                      paging.pageNumber,
                      paging.pageSize,
                      paging.sortBy || "routesRunActivity.sites.siteName",
                      paging.sortOrder || "ASC"
                  );
                };
              }}
          >
            <>{isMobile && teableHeader}</>
            <div className={styles.table_container}>
              {!isMobile && teableHeader}

              <div className={styles.table_subContainer}>
                <Table columns={colonne} type={"simpleNoAction"} paging={true}/>
              </div>
            </div>
          </PagingContextProvider>
        </div>
      </>
  );
};

export default Assist;
