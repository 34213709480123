/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NotificationDeviceDetailOutputDTO = {
    data?: string;
    type?: string;
    status?: NotificationDeviceDetailOutputDTO.status;
    unread?: boolean;
};

export namespace NotificationDeviceDetailOutputDTO {

    export enum status {
        OK = 'OK',
        KO = 'KO',
        WARNING = 'WARNING',
    }


}
