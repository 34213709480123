import { useWindowContext } from '@/hooks';
import styles from './Table.module.css'
import { t } from 'i18next';


/*
Table types

"type1" => Ranking table (ex: Sites page)
"type2" => Collapsed table (ex: Site page)

*/

type HeaderProps<T extends Record<string, any>, K extends Partial<Record<Extract<keyof T, string>, string>>> = {
    columns: K;
    type: "sitesRanking" | "amrRanking" | "simpleNoAction" | "simpleAction" | "simpleMap" | "collapsed" | "notification" | "amrRankingOnlyAction" | "amrRankingWithoutAction" | "sitesRankingOnlyAction" | "sitesRankingWithoutAction"
};
const Header = <T extends Record<string, any>, K extends Partial<Record<Extract<keyof T, string>, string>>>({
    columns,
    type
}: HeaderProps<T, K>) => {
    const values = Object.values(columns);
    const cols = values.map((column, index) => {
        if (typeof column !== 'undefined') {
            //Fa sparire la colonna visited
            if(type === "notification"){
                if(column !== "Visited"){
                    return <th key={index} className={column==="Rank"? styles.thFirst:"" && styles.th}>{column}</th>
                } else return <></>
            } else {
                if(column === t("columns.machineDetails.usagePeriod")){
                    return <th key={index} className={styles.thUsagePeriod}>{column}</th>
                }
                return <th key={index} className={column==="Rank"? styles.thFirst: styles.th}>{column}</th>
            }
        }
        return <></>;
    });
    switch (type) {
        case "sitesRankingOnlyAction":
        case "amrRankingOnlyAction":{
            return (
                <thead>
                    <tr className={styles.trHeadAction}>{cols}<th className={styles.thAction}>{t('columns.armMachines.action')}</th></tr>
                </thead>
            );
        }
        case "sitesRanking":
        case "amrRanking":
        case "simpleAction":
        case "notification": {
            return (
                <thead>
                    <tr className={styles.trHead}>{cols}<th className={styles.th}>{t('columns.armMachines.action')}</th></tr>
                </thead>
            );
        }
        case "simpleMap":{
            return (
                <thead>
                    <tr>{cols}<th className={styles.th}>Map</th></tr>
                </thead>
            );
        }
        case "amrRankingWithoutAction":
        case "sitesRankingWithoutAction":
        case "collapsed":
        case "simpleNoAction": {
            return (
                <thead>
                    <tr>{cols}</tr>
                </thead>
            );
        }
        default: {
            return (<></>);
        }
    }

};
export default Header;



