/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SingleChartsOutputDTO } from '../models/SingleChartsOutputDTO';
import type { UserPerCompanyOutputDTO } from '../models/UserPerCompanyOutputDTO';
import type { UserPerCountryOutputDTO } from '../models/UserPerCountryOutputDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomUserAnalyticsResourceService {

    /**
     * Retrieves number of logged users in input period 
     * Given a start and an end date searches and returns number of distinct users logged in that period
     * @param startDate The start date to filter by
     * @param endDate The end date to filter by
     * @returns number OK
     * @throws ApiError
     */
    public static getUsersPerPeriod(
startDate: string,
endDate: string,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/user-analytics-users-per-period',
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves number of logged users in every day of input period 
     * Given a start and an end date searches and returns number of distinct users logged in in every day of that period
     * @param startDate The start date to filter by
     * @param endDate The end date to filter by
     * @returns SingleChartsOutputDTO OK
     * @throws ApiError
     */
    public static getUsersPerDay(
startDate: string,
endDate: string,
): CancelablePromise<Array<SingleChartsOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/user-analytics-users-per-day',
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves number of logged users in input period for each country
     * Given a start and an end date searches and returns number of distinct users for each country logged in that period
     * @param startDate The start date to filter by
     * @param endDate The end date to filter by
     * @returns UserPerCountryOutputDTO OK
     * @throws ApiError
     */
    public static getUsersPerCountry(
startDate: string,
endDate: string,
): CancelablePromise<Array<UserPerCountryOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/user-analytics-users-per-country',
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves number of logged users in input period for each company
     * Given a start and an end date searches and returns number of distinct users for each company logged in that period
     * @param startDate The start date to filter by
     * @param endDate The end date to filter by
     * @returns UserPerCompanyOutputDTO OK
     * @throws ApiError
     */
    public static getUsersPerCompany(
startDate: string,
endDate: string,
): CancelablePromise<Array<UserPerCompanyOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/user-analytics-users-per-company',
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
