/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SitesDTO } from '../models/SitesDTO';
import type { SitesView } from '../models/SitesView';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomSitesResourceService {

    /**
     * Retrieves all sites associated with the current user
     * @returns SitesDTO OK
     * @throws ApiError
     */
    public static getAllSites1(): CancelablePromise<Array<SitesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/sites',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves all sites for backoffice purposes
     * @returns SitesView OK
     * @throws ApiError
     */
    public static getSites1(): CancelablePromise<Array<SitesView>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/sites-projection',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves site with a specific idSite 
     * @param id Site ID to filter by
     * @returns SitesDTO OK
     * @throws ApiError
     */
    public static getSiteById(
id: number,
): CancelablePromise<SitesDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/site/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves site by route
     * Return the site associated to the route
     * @param routeId The ID of the route to filter by
     * @returns SitesDTO OK
     * @throws ApiError
     */
    public static getSiteByRoute(
routeId: number,
): CancelablePromise<Array<SitesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/site-route/{routeId}',
            path: {
                'routeId': routeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves sites by multiple filters
     * Return sites filtered by device model, routes and multiple device rin
     * @param routeId 
     * @param deviceModel 
     * @param deviceRins 
     * @returns SitesDTO OK
     * @throws ApiError
     */
    public static getSitesByMultipleFilters(
routeId?: number,
deviceModel?: string,
deviceRins?: Array<string>,
): CancelablePromise<Array<SitesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/site-multiple-filters',
            query: {
                'routeId': routeId,
                'deviceModel': deviceModel,
                'deviceRins': deviceRins,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves sites by device model
     * Return sites associated to devices of a certain model and associated with the current user
     * @param deviceModel The device model of the devices to filter by
     * @returns SitesDTO OK
     * @throws ApiError
     */
    public static getSitesByDeviceModel(
deviceModel: string,
): CancelablePromise<Array<SitesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/site-devicesModel/{deviceModel}',
            path: {
                'deviceModel': deviceModel,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
