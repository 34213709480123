import React, { useContext, useEffect, useRef, useMemo, useCallback, useState } from 'react'
import styles from './Site.module.css'
import Maps from '@/components/UI/Maps'
import { useTranslation } from 'react-i18next'
import Label from '@/components/UI/Label'
import FilterBar from '@/components/Filter/Filterbar'
import MachineDetails from '@/components/MachineDetails'
import { useRemoteGet, useWindowContext } from '@/hooks'
import { CustomSiteDetailPageResourceService, CustomSitesResourceService } from '@/services/openapi'
import Box from '../../components/Box'
import LineCharts from '../../components/UI/Chart/LineCharts'
import ContentLabel from '@/components/ContentLabel'
import calendar_active from '@Images/calendar_filter.svg'
import calendar_active_mobile from '@Images/icon_calendar.svg'
import Legend from '@/components/Legend'
import Table from '@/components/UI/Table/Table'
import moment from 'moment-timezone'
import MainContext from '@/contexts/MainContext'
import { useLocation } from "react-router-dom";
import { PagingContextProvider } from "@/contexts/providers/PagingContextProvider";
import { Location } from "@Ui/Maps/Maps";
import { formDateToTimestamp } from "@/utils";
import { useFilterDateContext } from '@/hooks/FilterDateContext/useFilterDateContext'
import Img from '@/components/UI/Img'
import sites_mobile_icon from '@Images/Icon_point.svg'
import { ComboDateProps } from '@/components/UI/Date/ComboDate/ComboDate'
import CustomLoader from '@/components/UI/CustomLoader';
import Button from '@/components/UI/Button'

let tmpMarkerList: Location[] = [];

const Site = () => {

    const { t } = useTranslation();
    const { isMobile, size } = useWindowContext()
    const location = useLocation()
    const params = new URLSearchParams(location.search);
    const siteId = params.get("id") || undefined;

    const {date1,date2,setDate1,setDate2, timezone} = useFilterDateContext()
    const date1InputRef = useRef(null);
    const date2InputRef = useRef(null);

    const siteData = useRemoteGet({
        remoteMethod: () => {
            return CustomSitesResourceService.getSiteById(parseInt(params.get("id") ?? ""));
        },
        lazy: false
    });

    const numDeviceOnSiteData = useRemoteGet({
        remoteMethod: () => {
            return CustomSiteDetailPageResourceService.getNumDeviceOnSite(parseInt(params.get("id") ?? ""));
        },
        lazy: false
    });

    const numRoutesOnSiteData = useRemoteGet({
        remoteMethod: () => {
            return CustomSiteDetailPageResourceService.getNumRoutesOnSite(parseInt(params.get("id") ?? ""));
        },
        lazy: false
    });


    const m2TotCoveredLastWeekData = useRemoteGet({
        remoteMethod: () => {
            return CustomSiteDetailPageResourceService.getM2CoveredLastWeek(moment.utc().subtract(7, 'days').format(), moment.utc().format(), timezone, parseInt(params.get("id") ?? ""));
        },
        lazy: false
    });

    const deviceDetailsTableData = useRemoteGet({
        remoteMethod: () => {
            return CustomSiteDetailPageResourceService.sitePageTable1(formDateToTimestamp(date1), formDateToTimestamp(date2, true), timezone, parseInt(params.get("id") ?? ""));
        },
        lazy: false
    });

    // const coverageM2Data = useRemoteGet({
    //     remoteMethod: () => {
    //         return CustomSiteDetailPageResourceService.getM2CoveredPercChart(formDateToTimestamp(date1), formDateToTimestamp(date2, true), timezone, parseInt(params.get("id") ?? ""));
    //     },
    //     lazy: false
    // });

    // const coverageM2ChartData = coverageM2Data.data?.map((obj) => {
    //     return { name: obj.date, uv: obj.value }
    // }) ?? [{ name: "", uv: 0 }];

    const comboDate: ComboDateProps = {
        idDate1: 'data1',
        nameDate1: 'Data1',
        valueDate1: date1,
        idDate2: 'data2',
        nameDate2: 'Data2',
        valueDate2: date2,
        imgSrc: (size.width as number > 900) ? calendar_active : calendar_active_mobile,
        min: 'string',
        max: 'string',
        labelName: 'string',
        textLabel: t('dashboard.period'),
        ref1: date1InputRef,
        onChangeDate1: (e: any) => {
            setDate1(e.target.value)
        },
        ref2: date2InputRef,
        onChangeDate2: (e: any) => {
            setDate2(e.target.value)
        }
    }

    // const lineChart1 = {
    //     id: 'coverageM2',
    //     data: coverageM2ChartData,
    //     widthRC: "100%",
    //     heightRC: "100%",
    //     widthLineC: 1150,
    //     heightLineC: 200,
    //     dataKey: "name",
    //     top: 30,
    //     right: 25,
    //     left: -20,
    //     bottom: 20,
    //     color: "#FC8D41",
    //     labelX: t('chart.date'),
    //     labelY: t('chart.value'),
    //     labelY2: t('chart.previousValue')
    // };
    // const lineChart1Mobile = {
    //     id: 'coverageM2',
    //     data: coverageM2ChartData,
    //     widthRC: "100%",
    //     heightRC: "100%",
    //     widthLineC: (size.width as number) - 40,
    //     heightLineC: 250,
    //     dataKey: "name",
    //     top: 20,
    //     right: 40,
    //     left: 0,
    //     bottom: 25,
    //     color: "#FC8D41",
    //     labelX: t('chart.date'),
    //     fontSizeYAxis: 11,
    //     labelY: t('chart.value'),
    //     labelY2: t('chart.previousValue')
    // };

    const colonne = {
        data: t('columns.site.data'),
        routeRuns: t('columns.site.routeRuns'),
        totRouteDuration: t('columns.site.totRouteDuration'),
        areaCovered: t('columns.site.areaCovered'),
        areaCoveredPerHour: t('columns.site.areaCoveredPerHour'),
        assists: t('columns.site.assists'),
    }

    const columns = {
        rin: t('columns.site.rin'),
        startTime: t('columns.site.startTime'),
        site: t('columns.site.site'),
        route: t('columns.site.route'),
        reason: t('columns.site.reason'),
    }


    const [titleLoaded, setTitleLoaded] = useState<boolean>(false);
    const titleCtx = useContext(MainContext);

    useEffect(() => {

        if (!titleLoaded) {
            if (typeof siteData.data !== 'undefined') {
                titleCtx.setTitle(<Label text={(siteData.data?.siteName) + (!siteData.data?.siteLocality ? "" : ", " + siteData.data?.siteLocality)}
                    className={styles.titleLabel} />)
                setTitleLoaded(true);
            }
        }
    }, [siteData])

    const [errorDataPeriod, setErrorDataPeriod] = useState(false);

    const buttonRef = useRef<HTMLButtonElement>(null);

    const applyFilters = useCallback(() => {
        if (date1 <= date2) {
            setErrorDataPeriod(false)
            if (!siteData.loading) siteData.fetch();
            if (!numDeviceOnSiteData.loading) numDeviceOnSiteData.fetch();
            if (!numRoutesOnSiteData.loading) numRoutesOnSiteData.fetch();
            if (!m2TotCoveredLastWeekData.loading) m2TotCoveredLastWeekData.fetch();
            if (!deviceDetailsTableData.loading) deviceDetailsTableData.fetch();
        }
        else {
            setErrorDataPeriod(true);
            setTimeout(() => {
                setErrorDataPeriod(false);
            }, 2000);
        }
    }, [date1, date2, siteData, numDeviceOnSiteData, numRoutesOnSiteData, m2TotCoveredLastWeekData, deviceDetailsTableData]);

    const loader = () => {
        if (siteData.loading 
          || numDeviceOnSiteData.loading 
          || numRoutesOnSiteData.loading 
          || m2TotCoveredLastWeekData.loading 
          || deviceDetailsTableData.loading)
        return <span><CustomLoader searchLoader={true} /></span>
        else return <span></span>
    }

    const applyFiltersButton = useMemo(() => {
        return  <div className={styles.searchBtnContainer}>
                    <Button 
                        ref={buttonRef}
                        name={t('dashboard.applyFilters')} 
                        className={styles.searchBtn}
                        onClick={applyFilters} />
                    {loader()}
                </div>
    }, [applyFilters, buttonRef]);

    return (
        <div className={styles.main_container}>
            {isMobile && <div className={styles.titleMobileContainer}>
                <Label text={siteData.data?.siteName + " - " + siteData.data?.siteLocality} className={styles.titleSiteMobile} />
                <div className={styles.subtitleSiteMobileContainer}>
                    <Img alt='' src={sites_mobile_icon} className={styles.subtitleSiteMobileIcon} />
                    <div className={styles.titleMobileContainer}>
                        <Label text={siteData.data?.streetName ?? ''} className={styles.subtitleSiteMobile} />
                        <Label text={siteData.data?.siteCountry ?? ''} className={styles.subtitleSiteMobile} />
                    </div>
                </div>
            </div>}

            <Box className={styles.map_container} children={
                <Maps className={styles.map} sites={siteData.data ? [siteData.data] : []} />
            } />
            <div className={styles.siteNumbers_main_container}>
                <ContentLabel containerClassname={styles.siteNumbers_container} label1={'' + numDeviceOnSiteData.data}
                    label1className={styles.siteNumbers_numberLabel} label2={t('site.machinesOnSite')}
                    label2className={styles.siteNumbers_titleLabel} />
                <ContentLabel containerClassname={styles.siteNumbers_container} label1={'' + numRoutesOnSiteData.data}
                    label1className={styles.siteNumbers_numberLabel} label2={t('site.routesNumber')}
                    label2className={styles.siteNumbers_titleLabel} />
                <ContentLabel containerClassname={`${styles.siteNumbers_container} ${styles.siteNumbers_container_last}`}
                    label1={'' + m2TotCoveredLastWeekData.data}
                    label1className={styles.siteNumbers_numberLabel} label2={t('site.m2Covered')}
                    label2className={styles.siteNumbers_titleLabel} label3={t('site.lastWeek')}
                    label3className={styles.siteNumbers_subtitle_label} />
            </div>

            {!isMobile && <Label text={t('site.machineUsage')} className={styles.titleLabel} />
            }
            <Box className={styles.filterbar_container} children={
                <FilterBar filters={[{ type: 'Date', selectData: comboDate, boxClassName: styles.box_period, dataError: errorDataPeriod }]} />
            } />
            {applyFiltersButton}

            {
                deviceDetailsTableData.data?.map((device, index) => {
                    return (
                        <>
                            <MachineDetails key={index} id={index} type={device.deviceTipe ?? ""}
                                name={device.deviceName ?? (siteData.data?.siteName + " - " + siteData.data?.siteLocality) ?? ""}
                                auto={device.autoTotalDay} manual={device.manualTotalDay}
                                training={device.trainingTotalDay} autonomus={device.autoPercTotalDay}
                                details={device.siteDetailPage ?? []} detailsLoading={deviceDetailsTableData.loading}

                            />
                        </>
                    )
                })
            }

            {/* {
        data.devices.map((device, index) => {
          return (
            <>
              <MachineDetails key={index} id={device.id} type={device.type} usage={device.usage} auto={device.auto} manual={device.manual} training={device.training} autonomus={device.autonomus} />
            </>
          )
        })
      } */}

            {/*<Box className={styles.box_grafici_1} children={*/}
            {/*    <div className={styles.box_chart}>*/}
            {/*        <div className={styles.title_chart_container}>*/}
            {/*            <Label text={t('chart.coverage')} className={styles.title_chart} />*/}
            {/*            <Legend containerClassname={styles.containerLegend} legendStyle={styles.chartLegend} label1={t("site.autonomousArea")}*/}
            {/*                label1className={styles.chartLegendLabel} />*/}
            {/*        </div>*/}

            {/*        <div className={styles.chart}>*/}
            {/*            <LineCharts {...(isMobile ? lineChart1Mobile : lineChart1)} />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*} />*/}
            {isMobile && <div className={styles.tableHeader}>
                <Label text={t('site.routeAnalysis')} className={''} />
            </div>}
            <div className={styles.table_container}>
                {!isMobile && <div className={styles.tableHeader}>
                    <Label text={t('site.routeAnalysis')} className={''} />
                </div>}

                <div className={styles.table_subContainer}>
                    <PagingContextProvider searchRef={buttonRef} queryParams={{ date1, date2, site: siteId }}
                        remoteMethod={(queryParams, paging) => {
                            return () => {
                                if (typeof queryParams.site !== 'undefined') {
                                    return CustomSiteDetailPageResourceService.getAllRoutesByDeviceId(
                                        formDateToTimestamp(queryParams.date1!),
                                        formDateToTimestamp(queryParams.date2!, true),
                                        timezone,
                                        parseInt(queryParams.site),
                                        paging.pageNumber,
                                        paging.pageSize,
                                        paging.sortBy,
                                        paging.sortOrder)
                                }
                            }
                        }}>
                        <Table columns={colonne} type={"simpleNoAction"} paging={true} />
                    </PagingContextProvider>
                </div>

            </div>


        </div>
    )
}

export default Site
