import Table from "@/components/UI/Table/Table";
import {useRemoteGet, useWindowContext} from "@/hooks";
import {
  CustomDeviceResourceService,
  CustomRoutesResourceService,
  CustomSitesPageResourceService,
  CustomSitesResourceService,
} from "@/services/openapi";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Maps from "@/components/UI/Maps";
import {ComboSelectProps} from "@/components/UI/Select/ComboSelect/ComboSelect";
import {useTranslation} from "react-i18next";
import calendar_active from "@Images/calendar_filter.svg";
import machine_active from "@Images/MMachines_filter.svg";
import sites_active from "@Images/sites_filter.svg";
import DoubleFilterBar from "@/components/Filter/DoubleFilterBar";
import styles from "./Sites.module.css";
import Button from "@Ui/Button";
import bestPerformingIcon from "../../assets/images/whiteBestPerforming.svg";
import underPerformingIcon from "../../assets/images/whiteUnderPerforming.svg";
import TableTitle from "@/components/TableTitle";
import downloadIcon from "../../assets/images/download.svg";
import Label from "@/components/UI/Label";
import MainContext from "@/contexts/MainContext";
import {PagingContextProvider} from "@/contexts/providers/PagingContextProvider";
import {modelData} from "@/deviceModels";
import DownloadButton from "@/components/UI/DownloadButton";
import {formDateToTimestamp} from "@/utils";
import ButtonMobile from "@/components/UI/ButtonMobile";
import calendar_active_mobile from "@Images/icon_calendar.svg";
import machine_active_mobile from "@Images/icon_machine.svg";
import sites_active_mobile from "@Images/Icon_point.svg";
import TableMobile from "@/components/UI/Table/TableMobile";
import {SelectInstance, SingleValue, MultiValue} from "react-select";
import {SelectDataProps} from "@Ui/Select/SelectBox";
import {useFilterDateContext} from "@/hooks/FilterDateContext/useFilterDateContext";
import CustomLoader from "@Ui/CustomLoader";
import {RoutesDTO} from "@/services/openapi";

const Sites = () => {
  const {size, isMobile} = useWindowContext();

  const [showFiltersPage, setShowFiltersPage] = useState<boolean>(false);
  const {t} = useTranslation();

  const [showFilterValue, setShowFilterValue] = useState<boolean>(true);

  const siteRef = useRef<SelectInstance<SelectDataProps> | null>(null);
  const [site, setSite] = useState<string>("All");
  const [siteIsUserTriggered, setSiteIsUserTriggered] = useState<boolean>(false);

  const routeRef = useRef<SelectInstance<SelectDataProps> | null>(null);
  const [route, setRoute] = useState<string>("All");
  const [routeIsUserTriggered, setRouteIsUserTriggered] =
      useState<boolean>(false);

  const modelRef = useRef<SelectInstance<SelectDataProps> | null>(null);
  const [model, setModel] = useState<string>("All");
  const [modelIsUserTriggered, setModelIsUserTriggered] =
      useState<boolean>(false);

  const typeRef = useRef<SelectInstance<SelectDataProps> | null>(null);
  const [type, setType] = useState<string>("All");
  const [typeIsUserTriggered, setTypeIsUserTriggered] = useState<boolean>(false);

  const rinRef = useRef<SelectInstance<SelectDataProps> | null>(null);
  const [multipleRin, setMultipleRin] = useState<string[]>([]);
  const [multipleRinIsUserTriggered, setMultipleRinIsUserTriggered] =
      useState<boolean>(false);

  const {date1, date2, setDate1, setDate2, timezone} = useFilterDateContext();
  const date1InputRef = useRef(null);
  const date2InputRef = useRef(null);

  const [bestPerformingIsOpen, setBestPerformingIsOpen] =
      useState<boolean>(true);
  const [underPerformingIsOpen, setUnderPerformingIsOpen] =
      useState<boolean>(true);

  const sitesData = useRemoteGet({
    remoteMethod: () => {
      // const routeId = isNaN(parseInt(route)) ? undefined : parseInt(route);
      // return CustomSitesResourceService.getSitesByMultipleFilters(
      //   routeId,
      //   model,
      //   multipleRin
      // );
      return CustomSitesResourceService.getAllSites1();
    },
    lazy: false,
  });

  const routesData = useRemoteGet({
    remoteMethod: () => {
      const siteId = isNaN(parseInt(site)) ? undefined : parseInt(site);
      return CustomRoutesResourceService.getRoutesByMultipleFilters(
          siteId,
          model,
          multipleRin.length === 0 ? ['All'] : multipleRin
      );
    },
    lazy: false,
  });

  const devicesData = useRemoteGet({
    remoteMethod: () => {
      const routeId = isNaN(parseInt(route)) ? undefined : parseInt(route);
      const siteId = isNaN(parseInt(site)) ? undefined : parseInt(site);
      if (siteIsUserTriggered && siteId) {
        return CustomDeviceResourceService.getAllDevicesBySiteId(siteId);
      }

      return CustomDeviceResourceService.getDevicesByMultipleFilters(
          siteId,
          routeId,
          model,
          multipleRin.length === 0 ? ['All'] : multipleRin
      );
    },
    lazy: false,
  });

  useEffect(() => {
    sitesData.fetch();
    routesData.fetch();
    devicesData.fetch();
  }, []);

  // Update filters when site changes
  useEffect(() => {
    if (siteIsUserTriggered) {
      devicesData.fetch();
      routesData.fetch();
    }
  }, [site]);

  // Update filters when route changes
  useEffect(() => {
    if (routeIsUserTriggered) {
      devicesData.fetch();
    }
  }, [route]);

  // Update filters when machine model changes
  useEffect(() => {
    //sitesData.fetch();
    //routesData.fetch();
    if (modelIsUserTriggered) {
      devicesData.fetch();
    }
  }, [model]);

  useEffect(() => {
    if (siteIsUserTriggered && site === 'All' && model === 'All' && route === 'All' && multipleRin.length === 0) {
      setSiteIsUserTriggered(false);
    }
  }, [site, route, model, multipleRin]);

  const comboDate = {
    idDate1: "data1",
    nameDate1: "Data1",
    valueDate1: date1,
    idDate2: "data2",
    nameDate2: "Data2",
    valueDate2: date2,
    min: "string",
    max: "string",
    labelName: "string",
    textLabel: t("dashboard.period"),
    imgSrc:
        (size.width as number) > 900 ? calendar_active : calendar_active_mobile,
    ref1: date1InputRef,
    onChangeDate1: (e: any) => {
      setDate1(e.target.value);
    },
    ref2: date2InputRef,
    onChangeDate2: (e: any) => {
      setDate2(e.target.value);
    },
  };

  const setSitesOptionsSelected = useCallback(
      (site: string) => {
        let data: SelectDataProps = {value: "All", label: "All"};

        const updateDataBySite = () => {
          const sites = sitesData.data?.find(
              (sites) => sites.id === parseInt(site)
          );
          data = {
            value: sites?.id?.toString() ?? "",
            label: sites?.siteName ?? "",
          };
          return data;
        };

        if (siteIsUserTriggered) {
          if (site === "All") {
            return data;
          } else {
            return updateDataBySite();
          }
        } else {
          if (multipleRin.length > 1) {
            return data;
          } else if (
              multipleRin.length === 1 &&
              !multipleRin.includes("All") &&
              sitesData.data &&
              devicesData.data
          ) {
            const device = devicesData.data.find(
                (device) => device.rin === multipleRin[0]
            );
            const sites = sitesData.data.find(
                (sites) => sites.id === device?.siteId
            );
            data = {
              value: sites?.id?.toString() ?? "",
              label: sites?.siteName ?? "",
            };
            return data;
          } else if (site !== "All") {
            return updateDataBySite();
          }
        }

        return data;
      },
      [devicesData.data, multipleRin, sitesData.data]
  );

  const setRoutesOptions = useCallback(() => {
    let data: SelectDataProps[] = [];

    const defaultData = () => {
      return routesData.data?.map((val) => {
        return {
          value: "" + val.id ?? "",
          label: `${val.routeName ?? ""}${
              val.routeLabel ? ` - ${val.routeLabel}` : ""
          }`,
        };
      }) ?? [];
    }

    if (
        !siteIsUserTriggered &&
        multipleRin.length === 1 &&
        !multipleRin.includes("All") &&
        sitesData.data &&
        devicesData.data
    ) {
      const device = devicesData.data.find(
          (device) => device.rin === multipleRin[0]
      );
      const sites = sitesData.data.find((sites) => sites.id === device?.siteId);
      const routes: RoutesDTO[] =
          routesData.data?.filter((route) => route.siteId === sites?.id) ?? [];
      routes.map((route) => {
        data.push({
          value: "" + route.id ?? "",
          label: `${route.routeName ?? ""}${
              route.routeLabel ? ` - ${route.routeLabel}` : ""
          }`,
        });
      });
    } else {
      return defaultData();
    }
    return data;
  }, [routesData.data]);

  const setSelectedRoute = useCallback(
      (route: string) => {
        let data: SelectDataProps = {value: "All", label: "All"};
        if (routesData.data && route !== "All") {
          const routes = routesData.data.find((r) => r.id === parseInt(route));
          data = {
            value: routes?.id?.toString() ?? "",
            label: routes?.routeName?.concat(" - " + routes.routeLabel) ?? "",
          };
        }
        return data;
      },
      [routesData.data]
  );

  const setModelOptions = useCallback(() => {
    return modelData.map((val) => {
      return {value: val.key ?? "", label: val.model ?? ""};
    }) ?? [];
  }, []);

  const selectTypeMachine: ComboSelectProps = {
    selectData: [{value: "AMR", label: "AMR"}],
    textLabel: t("dashboard.type"),
    nameSelect: "selectTypeMachine",
    imgSrc:
        (size.width as number) > 900 ? machine_active : machine_active_mobile,
    firstOption: "All",
    ref: typeRef,
    valueSelected: {value: type, label: type},
    isUserTriggered: typeIsUserTriggered,
    onChange: (newValue, actionMeta, isUserTriggered) => {
      setTypeIsUserTriggered(isUserTriggered);
      // setSiteIsUserTriggered(!isUserTriggered);
      // setRouteIsUserTriggered(!isUserTriggered);
      // setModelIsUserTriggered(!isUserTriggered);
      // setMultipleRinIsUserTriggered(!isUserTriggered);

      const singleValue = newValue as SingleValue<SelectDataProps>;
      if (singleValue !== null && actionMeta.action === "select-option") {
        setType(singleValue.value);
      }
    },
  };

  const selectSites: ComboSelectProps = {
    selectData:
        sitesData.data?.map((val) => {
          return {value: "" + val.id ?? "", label: val.siteName ?? ""};
        }) ?? [],
    textLabel: t("sidebar.sites"),
    nameSelect: "selectSites",
    imgSrc: (size.width as number) > 900 ? sites_active : sites_active_mobile,
    firstOption: "All",
    ref: siteRef,
    valueSelected: setSitesOptionsSelected(site),
    isUserTriggered: siteIsUserTriggered,
    onChange: (newValue, actionMeta, isUserTriggered) => {
      setSiteIsUserTriggered(isUserTriggered);
      setTypeIsUserTriggered(false);
      setMultipleRinIsUserTriggered(false);
      setModelIsUserTriggered(false);
      setRouteIsUserTriggered(false);

      const singleValue = newValue as SingleValue<SelectDataProps>;
      if (isUserTriggered && singleValue?.value === 'All') {
        setModel('All');
        setMultipleRin([]);
      }
      if (singleValue !== null && actionMeta.action === "select-option") {
        setSite(singleValue.value);
      }
    },
  };

  const selectRoute: ComboSelectProps = {
    selectData: setRoutesOptions(),
    textLabel: t("dashboard.route"),
    nameSelect: "selectRoute",
    imgSrc: (size.width as number) > 900 ? undefined : machine_active_mobile,
    firstOption: "All",
    ref: routeRef,
    valueSelected: setSelectedRoute(route),
    isUserTriggered: routeIsUserTriggered,
    onChange: (newValue, actionMeta, isUserTriggered) => {
      setRouteIsUserTriggered(isUserTriggered);
      setSiteIsUserTriggered(false);
      // setTypeIsUserTriggered(!isUserTriggered);
      // setMultipleRinIsUserTriggered(!isUserTriggered);
      // setModelIsUserTriggered(!isUserTriggered);

      const singleValue = newValue as SingleValue<SelectDataProps>;
      if (singleValue !== null && actionMeta.action === "select-option") {
        setRoute(singleValue.value);
      }
    },
  };

  const selectModel: ComboSelectProps = {
    selectData: setModelOptions(),
    textLabel: t("dashboard.model"),
    nameSelect: "selectModel",
    imgSrc: (size.width as number) > 900 ? undefined : machine_active_mobile,
    firstOption: "All",
    ref: modelRef,
    valueSelected: {value: model, label: model},
    isUserTriggered: modelIsUserTriggered,
    onChange: (newValue, actionMeta, isUserTriggered) => {
      setModelIsUserTriggered(isUserTriggered);
      // setSiteIsUserTriggered(!isUserTriggered);
      // setRouteIsUserTriggered(!isUserTriggered);
      // setTypeIsUserTriggered(!isUserTriggered);
      // setMultipleRinIsUserTriggered(!isUserTriggered);

      const singleValue = newValue as SingleValue<SelectDataProps>;
      if (singleValue !== null && actionMeta.action === "select-option") {
        setModel(singleValue.value);
      }
    },
  };

  const selectRin: ComboSelectProps = {
    selectData:
        devicesData.data?.map((val) => {
          return {value: "" + val.rin ?? "", label: val.rin ?? ""};
        }) ?? [],
    textLabel: t("dashboard.rin"),
    nameSelect: "selectRin",
    imgSrc: (size.width as number) > 900 ? undefined : machine_active_mobile,
    ref: rinRef,
    multiValueSelected: multipleRin.map((rin) => {
      return {value: rin, label: rin};
    }),
    isMulti: true,
    isUserTriggered: multipleRinIsUserTriggered,
    onChange: (newValue, actionMeta, isUserTriggered) => {
      setMultipleRinIsUserTriggered(isUserTriggered);
      setSiteIsUserTriggered(false);
      // setRouteIsUserTriggered(!isUserTriggered);
      setModelIsUserTriggered(false);
      // setTypeIsUserTriggered(!isUserTriggered);

      const multiValue = newValue as MultiValue<SelectDataProps>;
      if (multiValue !== null) {
        if (multiValue.length === 0) {
          setMultipleRin([]);
        } else {
          const deviceValues = multiValue.map((item) => item.value);
          setMultipleRin(deviceValues);
          if (deviceValues.length === 1 && devicesData.data) {
            const device = devicesData.data.find(
                (device) => device.rin === deviceValues[0]
            );
            if (device && model !== device.deviceModel) {
              setModel(device.deviceModel!);
            }
          }
        }
      }
    },
  };

  const [errorDataPeriod, setErrorDataPeriod] = useState(false);

  const filterbar = {
    filters: [
      {
        type: "Date",
        selectData: comboDate,
        boxClassName: styles.box_period,
        dataError: errorDataPeriod,
      },
      {
        type: "Select",
        selectData: selectTypeMachine,
        boxClassName: styles.box_type,
      },
      {
        type: "Select",
        selectData: selectSites,
        boxClassName: styles.box_sites,
      },
      {
        type: "ButtonFilter",
        textButton: "-",
        selectData: selectSites,
        boxClassName: styles.box_reset_btn,
      },
    ],
    showFilter: showFilterValue,
    showFilterCallback: () => {
      setShowFilterValue(!showFilterValue);
    },
  };
  const filterbar1 = {
    filters: [
      {
        type: "Select",
        selectData: selectRoute,
        boxClassName: styles.boxContainer1,
      },
      {
        type: "Select",
        selectData: selectModel,
        boxClassName: styles.boxContainer1,
      },
      {
        type: "Select",
        selectData: selectRin,
        boxClassName: styles.boxContainer1,
      },
    ],
  };
  const filterbarMobile = {
    filters: [
      {
        type: "Date",
        selectData: comboDate,
        boxClassName: styles.box_period,
        dataError: errorDataPeriod,
      },
      {
        type: "Select",
        selectData: selectTypeMachine,
        boxClassName: styles.box_type,
      },
      {
        type: "Select",
        selectData: selectSites,
        boxClassName: styles.box_sites,
      },
      {
        type: "Select",
        selectData: selectRoute,
        boxClassName: styles.boxContainer1,
      },
      {
        type: "Select",
        selectData: selectModel,
        boxClassName: styles.boxContainer1,
      },
      {
        type: "Select",
        selectData: selectRin,
        boxClassName: styles.boxContainer1,
      },
    ],
  };

  const handleBestPerf = () => {
    setBestPerformingIsOpen(!bestPerformingIsOpen);
  };

  const handleUnderPerf = () => {
    setUnderPerformingIsOpen(!underPerformingIsOpen);
  };

  // type colonne = keyof SitesDTO;

  const colonne = {
    sitesId: t("columns.sites.sitesId"),
    sites: t("columns.sites.sites"),
    totalUsagePerDay: t("columns.sites.totalUsagePerDay"),
    autonomusUsage: t("columns.sites.autonomusUsage"),
    assistPerDay: t("columns.sites.assistPerDay"),
    manualHours: t("columns.sites.manualHours"),
    trainingHours: t("columns.sites.trainingHours"),
    score: t("columns.sites.score"),
    // benchmark: t('columns.sites.benchmark')
  };

  // const datoColonna = Object.keys(data[0]).forEach((key) => {

  //     colonne[key] = key;

  // })
  const titleCtx = useContext(MainContext);

  const [titleLoaded, setTitleLoeaded] = useState<boolean>(false);

  useEffect(() => {
    if (!titleLoaded) {
      if (sitesData.data) {
        titleCtx.setTitle(
            <>
              <Label
                  text={"" + sitesData.data.length + " "}
                  className={styles.titleLabelBlue}
              />
              <Label text={t("sidebar.sites")} className={styles.titleLabel}/>
            </>
        );
        setTitleLoeaded(true);
      }
    }
  }, [sitesData]);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const applyFilters = useCallback(() => {
    // console.log("TIPO: ", type, "\nSITE: ", site, "\nROUTE: ", route, "\nMODEL: ", model, "\nRIN: ", multipleRin);
    if (date1 <= date2) {
      setErrorDataPeriod(false);
      if (!titleLoaded) {
        if (sitesData.data) {
          titleCtx.setTitle(
              <>
                <Label
                    text={"" + sitesData.data.length + " "}
                    className={styles.titleLabelBlue}
                />
                <Label text={t("sidebar.sites")} className={styles.titleLabel}/>
              </>
          );
          setTitleLoeaded(true);
        }
      }
      // routesData.fetch();
      // devicesData.fetch();
    } else {
      setErrorDataPeriod(true);
      setTimeout(() => {
        setErrorDataPeriod(false);
      }, 2000);
    }
  }, [date1, date2, titleLoaded, sitesData, routesData, devicesData]);

  const loader = () => {
    if (sitesData.loading || routesData.loading || devicesData.loading)
      return (
          <span>
          <CustomLoader searchLoader={true}/>
        </span>
      );
    else return <span></span>;
  };

  const applyFiltersButton = useMemo(() => {
    return (
        <div className={styles.searchBtnContainer}>
          <Button
              ref={buttonRef}
              name={t("dashboard.applyFilters")}
              className={styles.searchBtn}
              onClick={applyFilters}
          />
          {loader()}
        </div>
    );
  }, [applyFilters, buttonRef]);

  return (
      <>
        <div className={styles.container}>
          {isMobile && (
              <div className={styles.title_container_header}>
                <Label
                    text={sitesData.data?.length + " "}
                    className={styles.title_labelColored_header}
                />
                <Label text={t("sidebar.sites")} className={""}/>
              </div>
          )}
          {/*-------------------FILTRI Mobile START---------------------------------- */}
          {isMobile && (
              <>
                <div className={styles.filter_mobile}>
                  <ButtonMobile
                      text={t("assist.filters")}
                      onClick={() => {
                        setShowFiltersPage(!showFiltersPage);
                      }}
                      iconStart="filtersIcon.svg"
                  />
                  {/* <Button name={t('assist.filters')} className={styles.button_mobile} iconStart={'filterIcon.svg'} onClick={()=>{}}/> */}
                </div>
                <div
                    className={
                      showFiltersPage
                          ? styles.filter_mobile_page
                          : styles.filter_mobile_page_hide
                    }
                >
                  <DoubleFilterBar filterBarData={filterbarMobile}/>
                </div>
              </>
          )}

          {/*------------------FILTRI Mobile END------------------------------------- */}

          {/*-------------------FILTRI DESKTOP START---------------------------------- */}
          {!isMobile && (
              <div className={styles.select_container}>
                <DoubleFilterBar filterBarData={filterbar}/>
                {showFilterValue && <DoubleFilterBar filterBarData={filterbar1}/>}
              </div>
          )}
          {/*------------------FILTRI DESKTOP END------------------------------------- */}

          {applyFiltersButton}

          <div className={styles.map_container}>
            <Maps className={styles.map} sites={sitesData.data ?? []}/>
          </div>

          {!isMobile && (
              <div className={styles.button_main_container}>
                <div className={styles.button_container}>
                  <Button
                      name={t("sites.bestPerforming")}
                      className={styles.outlined_green}
                      onClick={() => {
                        handleBestPerf();
                      }}
                  />
                  <Button
                      name={t("sites.underPerforming")}
                      className={styles.outlined_orange}
                      onClick={() => {
                        handleUnderPerf();
                      }}
                  />
                </div>
              </div>
          )}

          <div
              className={styles.table_container}
              style={bestPerformingIsOpen ? {} : {display: "none"}}
          >
            <div className={styles.table_title_header}>
              <TableTitle
                  color="green"
                  titleText={t("sites.bestPerforming")}
                  // firstButtonText={t('sites.compareBestSites')} secondButtonText={t('sites.download')}
                  titleIcon={bestPerformingIcon}
                  //secondButtonIcon={downloadIcon}
              />
              <DownloadButton
                  name={!isMobile ? t("sites.download") : ""}
                  className={styles.downloadBtn}
                  iconStart={downloadIcon}
                  filename={"best_performing_machines.csv"}
                  columns={colonne}
                  remoteMethod={() => {
                    return CustomSitesPageResourceService.sitePageTableUnpaged(
                        formDateToTimestamp(date1),
                        formDateToTimestamp(date2, true),
                        timezone,
                        true,
                        type !== "All" ? type : undefined,
                        site !== "All" ? parseInt(site!) : undefined,
                        multipleRin.includes("All") ? undefined : multipleRin,
                        model !== "All" ? model : undefined,
                        route !== "All" ? parseInt(route!) : undefined
                    );
                  }}
              />
            </div>
            <div className={styles.table_subContainer}>
              <PagingContextProvider
                  searchRef={buttonRef}
                  queryParams={{date1, date2, type, site, rin: multipleRin, model, route}}
                  remoteMethod={(queryParams, paging) => {
                    return () => {
                      return CustomSitesPageResourceService.sitePageTable(
                          formDateToTimestamp(queryParams.date1!),
                          formDateToTimestamp(queryParams.date2!, true),
                          timezone,
                          true,
                          queryParams.type !== "All" ? queryParams.type : undefined,
                          queryParams.site !== "All"
                              ? parseInt(queryParams.site!)
                              : undefined,
                          queryParams.rin?.includes("All") ? queryParams.rin : undefined,
                          queryParams.model !== "All" ? queryParams.model : undefined,
                          queryParams.route !== "All"
                              ? parseInt(queryParams.route!)
                              : undefined,
                          paging.pageNumber,
                          paging.pageSize,
                          paging.sortBy,
                          paging.sortOrder
                      );
                    };
                  }}
              >
                <>
                  {!isMobile && (
                      <Table
                          columns={colonne}
                          type={"sitesRanking"}
                          color={"green"}
                          paging={true}
                      />
                  )}
                  {isMobile && (
                      <TableMobile
                          {...{
                            columns: colonne,
                            type: "sitesRanking",
                            color: "green",
                          }}
                      />
                  )}
                </>
              </PagingContextProvider>
            </div>
          </div>

          <div
              className={styles.table_container}
              style={underPerformingIsOpen ? {} : {display: "none"}}
          >
            <div className={styles.table_title_header}>
              <TableTitle
                  color="orange"
                  titleText={t("sites.underPerforming")}
                  // secondButtonText={t('sites.download')} secondButtonIcon={downloadIcon}
                  titleIcon={underPerformingIcon}
              />
              <DownloadButton
                  name={!isMobile ? t("sites.download") : ""}
                  className={styles.downloadBtn}
                  iconStart={downloadIcon}
                  filename={"less_performing_machines.csv"}
                  columns={colonne}
                  remoteMethod={() => {
                    return CustomSitesPageResourceService.sitePageTableUnpaged(
                        formDateToTimestamp(date1),
                        formDateToTimestamp(date2, true),
                        timezone,
                        false,
                        type !== "All" ? type : undefined,
                        site !== "All" ? parseInt(site!) : undefined,
                        multipleRin.includes("All") ? undefined : multipleRin,
                        model !== "All" ? model : undefined,
                        route !== "All" ? parseInt(route!) : undefined
                    );
                  }}
              />
            </div>

            <div className={styles.table_subContainer}>
              <PagingContextProvider
                  searchRef={buttonRef}
                  queryParams={{date1, date2, type, site, rin: multipleRin, model, route}}
                  remoteMethod={(queryParams, paging) => {
                    return () => {
                      return CustomSitesPageResourceService.sitePageTable(
                          formDateToTimestamp(queryParams.date1!),
                          formDateToTimestamp(queryParams.date2!, true),
                          timezone,
                          false,
                          queryParams.type !== "All" ? queryParams.type : undefined,
                          queryParams.site !== "All"
                              ? parseInt(queryParams.site!)
                              : undefined,
                          queryParams.rin?.includes("All") ? queryParams.rin : undefined,
                          queryParams.model !== "All" ? queryParams.model : undefined,
                          queryParams.route !== "All"
                              ? parseInt(queryParams.route!)
                              : undefined,
                          paging.pageNumber,
                          paging.pageSize,
                          paging.sortBy,
                          paging.sortOrder
                      );
                    };
                  }}
              >
                <>
                  {!isMobile && (
                      <Table
                          columns={colonne}
                          type={"sitesRanking"}
                          color={"orange"}
                          paging={true}
                      />
                  )}
                  {isMobile && (
                      <TableMobile
                          {...{
                            columns: colonne,
                            type: "sitesRanking",
                            color: "orange",
                          }}
                      />
                  )}
                </>
              </PagingContextProvider>
            </div>
          </div>
        </div>
        {/* <TableVecchia /> */}
      </>
  );
};
export default Sites;
