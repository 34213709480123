/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SingleChartsBenchMarkOutputDTO } from '../models/SingleChartsBenchMarkOutputDTO';
import type { SingleChartsOutputDTO } from '../models/SingleChartsOutputDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomDashboardResourceService {

    /**
     * Retrieves the coverage per hour based on input data machine
     * Create an object with input data machine and return a list representing the coverage per hour for each day
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param typeMachine Type of machine to filter by
     * @param siteId Site ID to filter by
     * @param rins Device IDs to filter by
     * @param machineModel Model of machine
     * @param routeId Route ID to filter by
     * @returns SingleChartsOutputDTO OK
     * @throws ApiError
     */
    public static getCoverageM2PerHour(
dateStart: string,
dateEnd: string,
timezone: string,
typeMachine?: string,
siteId?: number,
rins?: Array<string>,
machineModel?: string,
routeId?: number,
): CancelablePromise<Array<SingleChartsOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/Coverage-m2-per-Hour',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'typeMachine': typeMachine,
                'siteId': siteId,
                'rins': rins,
                'machineModel': machineModel,
                'routeId': routeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the Autonomous Usage Percentage
     * Creates an object with a input data and returns a list of autonomous usage percentage by day
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param typeMachine Type of machine to filter by
     * @param siteId Site ID to filter by
     * @param rins Device IDs to filter by
     * @param machineModel Model of machine
     * @param routeId Route ID to filter by
     * @returns SingleChartsOutputDTO OK
     * @throws ApiError
     */
    public static getAutonomousUsagePerc(
dateStart: string,
dateEnd: string,
timezone: string,
typeMachine?: string,
siteId?: number,
rins?: Array<string>,
machineModel?: string,
routeId?: number,
): CancelablePromise<Array<SingleChartsOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/AutonomousUsage-perc',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'typeMachine': typeMachine,
                'siteId': siteId,
                'rins': rins,
                'machineModel': machineModel,
                'routeId': routeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves machine assist per hour
     * Creates an object with input data and return a list representing machine assist hours for each day 
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param typeMachine Type of machine to filter by
     * @param siteId Site ID to filter by
     * @param rins Device IDs to filter by
     * @param machineModel Model of machine
     * @param routeId Route ID to filter by
     * @returns SingleChartsBenchMarkOutputDTO OK
     * @throws ApiError
     */
    public static getAssistsPerHour(
dateStart: string,
dateEnd: string,
timezone: string,
typeMachine?: string,
siteId?: number,
rins?: Array<string>,
machineModel?: string,
routeId?: number,
): CancelablePromise<SingleChartsBenchMarkOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/Assists-per-hour',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'typeMachine': typeMachine,
                'siteId': siteId,
                'rins': rins,
                'machineModel': machineModel,
                'routeId': routeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
