/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JWTToken } from '../models/JWTToken';
import type { LoginVM } from '../models/LoginVM';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserJwtControllerService {

    /**
     * @param requestBody 
     * @returns JWTToken OK
     * @throws ApiError
     */
    public static authorize(
requestBody: LoginVM,
): CancelablePromise<JWTToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/authenticate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
