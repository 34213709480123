import Label from "@Components/UI/Label";
import MainContext from "@/contexts/MainContext";
import {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from './AppAnalytics.module.css';
import {useWindowContext} from "@/hooks";
import Box from "@Components/Box";
import {useFilterDateContext} from "@/hooks/FilterDateContext/useFilterDateContext";
import calendar_active from "@Images/calendar_filter.svg";
import calendar_active_mobile from "@Images/calendar_filter.svg";
import {ComboDateProps} from "@Components/UI/Date/ComboDate/ComboDate";
import {FilterBarProps} from "@Components/Filter/Filterbar/FilterBar";
import DoubleFilterBar from "@Components/Filter/DoubleFilterBar";
import LineCharts from "@Components/UI/Chart/LineCharts";
import {
  CustomUserAnalyticsResourceService,
  SingleChartsOutputDTO,
  UserPerCompanyOutputDTO,
  UserPerCountryOutputDTO
} from "@/services/openapi";
import {formatDate, formDateToTimestamp} from "@/utils";
import {ComposableMap, Geographies, Geography} from "react-simple-maps";
import {DataProps} from "@Components/UI/Chart/BarChart/BarChart";
import geoData from "@Assets/const/topology.json";

const AppAnalytics = () => {

  const titleCtx = useContext(MainContext);
  const {size, isMobile} = useWindowContext();
  const [usersNumber, setUsersNumber] = useState<Number>(0);
  const [usersPerDayData, setUsersPerDayData] = useState<SingleChartsOutputDTO[]>([]);
  const [usersPerCountryData, setUsersPerCountryData] = useState<UserPerCountryOutputDTO[]>([]);
  const [usersPerCompanyData, setUsersPerCompanyData] = useState<UserPerCompanyOutputDTO[]>([]);

  const [lineChartData, setLineChartData] = useState<DataProps[]>([]);

  const {t} = useTranslation();

  const {date1, date2, setDate1, setDate2} = useFilterDateContext();
  const date1InputRef = useRef(null);
  const date2InputRef = useRef(null);

  const comboDate: ComboDateProps = {
    idDate1: "data1",
    nameDate1: "Data1",
    valueDate1: date1,
    idDate2: "data2",
    nameDate2: "Data2",
    valueDate2: date2,
    min: "string",
    max: "string",
    labelName: "string",
    textLabel: t("dashboard.period"),
    imgSrc:
        (size.width as number) > 900 ? calendar_active : calendar_active_mobile,

    ref1: date1InputRef,
    onChangeDate1: (e: any) => {
      setDate1(e.target.value);
    },
    ref2: date2InputRef,
    onChangeDate2: (e: any) => {
      setDate2(e.target.value);
    },
  };

  const widthLineC = isMobile ? size.width as number * 0.85 : size.width as number - 350 > 1000 ? 1000 : size.width as number * 0.75;

  const lineChart1 = {
    id: "usageTrend",
    data: lineChartData,
    widthRC: "100%",
    heightRC: "100%",
    widthLineC: widthLineC,
    heightLineC: 250,
    dataKey: "name",
    top: 30,
    right: 25,
    left: -20,
    bottom: 20,
    color: "#09556F",
    bmLineY: 40,
    bmLineColor: "gray",
    hideX: false,
    labelX: t("chart.date"),
    labelY: t("appAnalytics.users"),
    labelY2: t("appAnalytics.users"),
  };

  const [errorDataPeriod, setErrorDataPeriod] = useState<boolean>(false);

  const filterbar: FilterBarProps = {
    filters: [
      {
        type: "Date",
        selectData: comboDate,
        boxClassName: styles.period_box,
        dataError: errorDataPeriod,
      },
    ]
  };

  useEffect(() => {
    titleCtx.setTitle(<Label text={t('sidebar.appAnalytics')} className={styles.titleLabel}/>)

  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const formattedDate1 = formDateToTimestamp(date1);
      const formattedDate2 = formDateToTimestamp(date2, true);
      try {
        const response = await CustomUserAnalyticsResourceService.getUsersPerPeriod(
            formattedDate1,
            formattedDate2);
        setUsersNumber(response);
      } catch (error) {
        console.error('Error fetching users data:', error);
      }
      try {
        const response = await CustomUserAnalyticsResourceService.getUsersPerDay(
            formattedDate1,
            formattedDate2);
        setUsersPerDayData(response);
      } catch (error) {
        console.error('Errore nel recupero dei dati degli utenti:', error);
      }
      try {
        const response = await CustomUserAnalyticsResourceService.getUsersPerCountry(
            formattedDate1,
            formattedDate2
        );
        setUsersPerCountryData(response);
      } catch (error) {
        console.error('Error fetching users per country data:', error);
      }
      try {
        const response = await CustomUserAnalyticsResourceService.getUsersPerCompany(
            formattedDate1,
            formattedDate2
        );
        setUsersPerCompanyData(response);
      } catch (error) {
        console.error('Error fetching country percentage change data:', error);
      }
    };

    if (date1 < date2) {
      setErrorDataPeriod(false);
      fetchData();
    } else {
      setErrorDataPeriod(true);
    }
  }, [date1, date2]);

  useEffect(() => {
    const data = usersPerDayData?.map((obj) => {
      if (obj.date && (obj.value || obj.value === 0)) {
        return {name: formatDate(obj.date, "D MMM"), uv: obj.value};
      } else {
        return {name: "", uv: 0};
      }
    }) ?? [{name: "", uv: 0}];
    setLineChartData(data);

  }, [usersPerDayData]);

  const getColorForCountry = (country: string) => {
    const countryData = usersPerCountryData.find(data => data.country === country);
    if (countryData && countryData.count) {
      const value = countryData.count;
      const maxColorValue = Math.max(...usersPerCountryData.map(data => data.count ?? 0));
      const colorValue = Math.floor((value / maxColorValue) * 255);
      const invertedColorValue = 255 - colorValue;
      return `rgb(${invertedColorValue}, ${invertedColorValue}, 255)`;
    }
    return 'lightgray';
  };

  return (
      <div className={styles.container}>
        {isMobile && <Label text={t('sidebar.appAnalytics')} className={styles.titleLabel}/>}
        <DoubleFilterBar filterBarData={filterbar}/>
        <Box
            className={styles.chart_box}
            children={
              <div>
                <Label
                    text={t('appAnalytics.usage')}
                    className={styles.box_title}
                />
                <Box
                    className={styles.users_number_box}
                    children={
                      <div>
                        <Label
                            text={t('appAnalytics.users')}
                            className={styles.users_number_title}
                        />
                        <div className={styles.users_number_container}>
                          <Label
                              text={"" + usersNumber}
                              className={styles.users_number}
                          />
                        </div>
                      </div>
                    }
                />
                <LineCharts {...lineChart1} />
              </div>
            }
        />
        <Box
            className={styles.country_box}
            children={
              <div>
                <Label
                    text={t('appAnalytics.site')}
                    className={styles.box_title}
                />
                <div className={styles.map_stats_container}>
                  <ComposableMap className={styles.map} projection="geoMercator"
                                 projectionConfig={{center: [0, 40], scale: 120}}>
                    <Geographies geography={geoData}>
                      {({geographies}) =>
                          geographies.map(geo => (
                              <Geography
                                  key={geo.id}
                                  geography={geo}
                                  fill={getColorForCountry(geo.properties.name)}
                                  stroke="white"
                                  strokeWidth={1.5}
                                  style={{
                                    default: {outline: "none"},
                                    hover: {outline: "none"},
                                    pressed: {outline: "none"},
                                  }}
                              />
                          ))
                      }
                    </Geographies>
                  </ComposableMap>
                  <div className={styles.stats_container}>
                    <div className={styles.stats}>
                      <div className={styles.stats_title_container}>
                        <Label text={t('appAnalytics.country')} className={styles.stats_title}/>
                        <Label text={t('appAnalytics.users')} className={styles.stats_title}/>
                      </div>
                      {usersPerCountryData.map(item => (
                          <div key={item.country} className={styles.stats_item}>
                            <div className={styles.country_name}>{item.country}</div>
                            {typeof item.percentageDelta !== 'undefined' && (
                                <div className={styles.table_value}>{item.count}&nbsp;
                                  {item.percentageDelta >= 0 ? (<span className={styles.percentage_up}>▲</span>)
                                      : (<span className={styles.percentage_down}>▼</span>)}
                                  <span className={styles.percentage_value}>
                                    {
                                      Math.abs(item.percentageDelta) % 1 === 0 ?
                                          `${Math.abs(item.percentageDelta).toFixed(0)}%` :
                                          `${Math.abs(item.percentageDelta).toFixed(1)}%`}
                                  </span>
                                </div>)
                            }
                          </div>
                      ))}
                    </div>
                    <div className={styles.stats}>
                      <div className={styles.stats_title_container}>
                        <Label text={t('appAnalytics.company')} className={styles.stats_title}/>
                        <Label text={t('appAnalytics.users')} className={styles.stats_title}/>
                      </div>
                      {usersPerCompanyData.map(item => (
                          <div key={item.company} className={styles.stats_item}>
                            <div className={styles.country_name}>{item.company}</div>
                            {typeof item.percentageDelta !== 'undefined' && (
                                <div className={styles.table_value}>{item.count}&nbsp;
                                  {item.percentageDelta >= 0 ? (<span className={styles.percentage_up}>▲</span>)
                                      : (<span className={styles.percentage_down}>▼</span>)}
                                  <span className={styles.percentage_value}>
                                    {
                                      Math.abs(item.percentageDelta) % 1 === 0 ?
                                          `${Math.abs(item.percentageDelta).toFixed(0)}%` :
                                          `${Math.abs(item.percentageDelta).toFixed(1)}%`}
                                  </span>
                                </div>)
                            }
                          </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            }
        />
      </div>
  );
};
export default AppAnalytics;
